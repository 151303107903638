import React, { useRef, useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useParams, useLocation, useNavigate } from 'react-router';
import { TabView, TabPanel } from 'primereact/tabview';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import replace from 'lodash/replace';
import capitalize from 'lodash/capitalize';

import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import Tasks from 'containers/Tasks/Index';
import EditableText from 'components/Form/EditableFields/Text';
import EditableDate from 'components/Form/EditableFields/Date';
import OverviewTab from './OverviewTab';
import VendorsTab from './VendorsTab';
import TimelineTab from './TimelineTab';
import FilesTab from './FilesTab';

import {
  EVENT_QUERY, EVENT_TEMPLATE_QUERY, EVENT_UPDATE_MUTATION, EVENT_TEMPLATE_DELETE_MUTATION,
} from './graphql';

const NON_TEMPLATE_TABS = ['overview', 'vendors', 'tasks', 'files', 'timeline'];
const TEMPLATE_TABS = ['tasks', 'files', 'timeline'];
function EventShow({ canCreateTasks }) {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const isTemplate = path.includes('templates');
  const tabs = isTemplate ? TEMPLATE_TABS : NON_TEMPLATE_TABS;
  const menuRef = useRef(null);
  const toastRef = useRef(null);
  const [currentVendor, setCurrentVendor] = useState(null);
  const [event, setEvent] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDate, setIsEditingDate] = useState(false);
  const { eventId, tabName, taskId } = useParams();
  const updatable = event?.isUpdatableByCurrentActor;
  const {
    control, setValue, getValues,
  } = useForm({
    defaultValues: {
      name: event?.name,
      templateName: event?.templateName,
      startDate: event?.startDate ? new Date(event.startDate) : null,
    },
  });

  useEffect(() => {
    if (event) {
      setValue('name', event.name);
      setValue('templateName', event.templateName);
      setValue('startDate', event?.startDate ? new Date(event.startDate) : null);
    }
  }, [event]);

  const [eventUpdateMutation] = useMutation(EVENT_UPDATE_MUTATION, {
    onCompleted: async ({ eventUpdate }) => {
      setEvent({ ...event, ...eventUpdate.event });
      setIsEditingName(false);
      setIsEditingDate(false);
    },
    onError: () => { showErrorToast(toastRef, 'Error fetching events'); },
  });

  useEffect(() => {
    const tabFromPath = tabName;

    if (tabs.includes(tabFromPath)) {
      setActiveTabIndex(tabs.indexOf(tabFromPath));
    } else if (isTemplate && taskId) {
      navigate(`/app/events/templates/${eventId}/tasks/${taskId}`);
    } else if (isTemplate) {
      navigate(`/app/events/templates/${eventId}/${tabs[0]}`);
    } else {
      navigate(`/app/events/${eventId}/${tabs[0]}`);
    }
  }, [tabName, event]);

  const [
    fetchTemplate, { loading: loadingEventTemplate, refetch: refetchEventTemplate },
  ] = useLazyQuery(
    EVENT_TEMPLATE_QUERY,
    {
      variables: { filters: { id: eventId } },
      onCompleted: (data) => {
        const eventData = data.currentActor.eventTemplates[0];

        setCurrentVendor(data.currentVendor);
        setEvent(eventData);
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const [deleteEventTemplateMutation] = useMutation(EVENT_TEMPLATE_DELETE_MUTATION, {
    onCompleted: async () => {
      showSuccessToast(toastRef, 'Successfully deleted event template');
      navigate('/app/events');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const [
    fetchEvent, { loading: loadingEvent, refetch: refetchEvent },
  ] = useLazyQuery(
    EVENT_QUERY,
    {
      variables: { filters: { id: eventId } },
      onCompleted: (data) => {
        const eventData = data.currentActor.events.edges[0]?.node;

        setCurrentVendor(data.currentVendor);
        setEvent(eventData);
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );
  const fetchQuery = isTemplate ? fetchTemplate : fetchEvent;

  useEffect(() => {
    fetchQuery();
  }, [eventId]);

  const onTabChange = ({ index }) => {
    navigate(replace(path, tabs[activeTabIndex], tabs[index]));
    setActiveTabIndex(index);
  };

  if (!event) { return null; }

  const items = [
    {
      label: 'Overview',
      command: () => onTabChange({ index: tabs.indexOf('overview') }),
    },
    {
      label: 'Vendors',
      command: () => onTabChange({ index: tabs.indexOf('vendors') }),
    },
    {
      label: 'Tasks',
      command: () => onTabChange({ index: tabs.indexOf('tasks') }),
    },
    {
      label: 'Files',
      command: () => onTabChange({ index: tabs.indexOf('files') }),
    },
    {
      label: 'Timeline',
      command: () => onTabChange({ index: tabs.indexOf('timeline') }),
    },
  ];

  const templateCurrentTab = () => {
    switch (activeTabIndex) {
      case 0: {
        return <Tasks canCreateTasks={canCreateTasks} isEventTemplate />;
      }
      case 1: {
        return <FilesTab isEventTemplate />;
      }
      case 2: {
        return (
          <TimelineTab
            isEventTemplate
            event={event}
            currentVendor={currentVendor}
            eventsLoading={loadingEventTemplate}
            refetchEvent={refetchEventTemplate}
          />
        );
      }
      default: return null;
    }
  };
  const nonTemplateCurrentTab = () => {
    switch (activeTabIndex) {
      case 0: {
        return <OverviewTab />;
      }
      case 1: {
        return <VendorsTab />;
      }
      case 2: {
        return <Tasks canCreateTasks={canCreateTasks} />;
      }
      case 3: {
        return <FilesTab />;
      }
      case 4: {
        return (
          <TimelineTab
            event={event}
            currentVendor={currentVendor}
            eventsLoading={loadingEvent}
            refetchEvent={refetchEvent}
          />
        );
      }
      default: return null;
    }
  };
  const currentTab = () => (isTemplate ? templateCurrentTab() : nonTemplateCurrentTab());

  const updateEvent = ({ name, templateName, startDate }) => {
    eventUpdateMutation({
      variables: {
        input: {
          id: eventId, templateName, name, startDate,
        },
      },
    });
  };

  const deleteButton = () => {
    const confirmDelete = () => confirmDialog({
      className: 'xl:w-3/12',
      message: 'Are you sure you want to delete this event template?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteEventTemplateMutation({ variables: { input: { id: eventId } } }),
    });

    return (
      <Button
        onClick={confirmDelete}
        className="xxs:w-full md:w-min text-nowrap bg-white border-red-500 text-red-500 mt-2"
        size="small"
        icon="pi pi-trash"
        label="Delete"
        type="button"
      />
    );
  };

  const templateInfo = ({ marginClass }) => (
    <div className="flex w-full items-center justify-between">
      <div className={`flex items-center text-gray-500 ${marginClass}`}>
        <p className="mr-2">
          Template Name:
        </p>
        <p>
          <EditableText
            text={event.templateName}
            isEditing={isEditingName}
            setIsEditing={setIsEditingName}
            name="templateName"
            updatable={updatable}
            onUpdate={updateEvent}
            control={control}
            setValue={setValue}
            getValues={getValues}
          />
        </p>
      </div>
      { deleteButton() }
    </div>
  );

  const nameAndDate = ({ marginClass }) => (
    <>
      <p className={`font-semibold text-xl text-gray-800 ${marginClass}`}>
        <EditableText
          text={event.name}
          isEditing={isEditingName}
          setIsEditing={setIsEditingName}
          name="name"
          updatable={updatable}
          onUpdate={updateEvent}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
      </p>
      <p className="font-normal text-base text-gray-700">
        <EditableDate
          name="startDate"
          value={event.startDate ? DateTime.fromISO(event.startDate).toLocaleString(DateTime.DATE_MED) : 'Date: TBD'}
          isEditing={isEditingDate}
          setIsEditing={setIsEditingDate}
          updatable={updatable}
          onUpdate={updateEvent}
          control={control}
        />
      </p>
    </>
  );

  const header = ({ marginClass = '' }) => {
    if (isTemplate) {
      return templateInfo({ marginClass });
    }
    return nameAndDate({ marginClass });
  };

  return (
    <>
      <div className="xxs:hidden md:block">
        <div className="flex items-center">
          { header({ marginClass: 'mr-4' }) }
        </div>
        <TabView activeIndex={activeTabIndex} onTabChange={onTabChange} className="mt-2">
          {
            tabs.map((tab) => (
              <TabPanel header={capitalize(tab)} key={`${tab}-tab`}>
                { currentTab() }
              </TabPanel>
            ))
          }
        </TabView>
      </div>

      <div className="xxs:block md:hidden p-0 m-0">
        { header({}) }
        <Menu model={items} popup ref={menuRef} />
        <Button icon="pi pi-bars" size="small" onClick={(e) => menuRef.current.toggle(e)} className="mb-6" />
        { currentTab() }
      </div>
    </>
  );
}

export default EventShow;
