import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Filters from 'components/Filters';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import { Toast } from 'primereact/toast';
import without from 'lodash/without';
import { showErrorToast } from 'utils/toastUtils';
import ManageDisplaySettings from './ManageDisplaySettings';
import ManageTemplatesModal from './ManageTemplatesForm/Modal';
import CreateEventModal from './CreateEventModal';
import EventsIndexTable from './IndexTable';
import { EVENTS_QUERY, TEMPLATES_QUERY } from './graphql';

const PAGE_SIZE = 25;
const DEFAULT_DISPLAY_SETTINGS = {
  showRejected: false,
};
function Events({
  canCreateEvents, canUpdateTemplates, refetchEvents, setRefetchEvents,
}) {
  const [showAddNewEventModal, setShowAddNewEventModal] = useState(false);
  const [eventsData, setEventsData] = useState(null);
  const [currentActor, setCurrentActor] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [showNoMatchingErrorMessage, setShowNoMatchingErrorMessage] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(JSON.parse(localStorage.getItem('selectedEventFilters')) || []);
  const [eventFilters, setEventFilters] = useState(JSON.parse(localStorage.getItem('eventFilters')) || {});
  const [displaySettings, setDisplaySettings] = useState(JSON.parse(localStorage.getItem('eventDisplaySettings')) || DEFAULT_DISPLAY_SETTINGS);
  const [showManageTemplatesModal, setShowManageTemplatesModal] = useState(false);
  const [eventTemplates, setEventTemplates] = useState([]);
  const [paginationBounds, setPaginationBounds] = useState({ first: PAGE_SIZE });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const toastRef = useRef(null);
  const filtersButtonRef = useRef(null);
  const displaySettingsRef = useRef(null);

  const setShowRejected = (showRejected) => {
    setDisplaySettings({ ...displaySettings, showRejected });
  };

  const setShowRemoved = (showRemoved) => {
    setDisplaySettings({ ...displaySettings, showRemoved });
  };

  const {
    refetch: refetchTemplates,
  } = useQuery(
    TEMPLATES_QUERY,
    {
      onCompleted: (data) => {
        setEventTemplates(data.currentActor?.eventTemplates || []);
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const refetchAndSetTemplates = async () => {
    const response = await refetchTemplates();

    setEventTemplates(response.data.currentActor?.eventTemplates || []);
  };

  const mapFilterParams = () => {
    if (filterParams === eventFilters) { return {}; }

    const newFilters = structuredClone(eventFilters);

    if (newFilters.inviteStatus?.length) {
      if (
        (!displaySettings.showRejected && newFilters.inviteStatus[0] === 'REJECTED')
        || (!displaySettings.showRemoved && newFilters.inviteStatus[0] === 'REMOVED')
      ) {
        setShowNoMatchingErrorMessage(true);
        return {};
      }
    } else {
      newFilters.inviteStatus = ['INVITED', 'APPROVED', 'REJECTED', 'REMOVED', 'REQUESTED'];

      if (!displaySettings.showRejected) {
        newFilters.inviteStatus = without((newFilters?.inviteStatus || []), 'REJECTED');
      }

      if (!displaySettings.showRemoved) {
        newFilters.inviteStatus = without((newFilters?.inviteStatus || []), 'REMOVED');
      }
    }

    setShowNoMatchingErrorMessage(false);
    setFilterParams(newFilters);
    return {};
  };

  const {
    error, refetch,
  } = useQuery(
    EVENTS_QUERY,
    {
      variables: { ...paginationBounds, filters: filterParams },
      onCompleted: (data) => {
        setEventsData(data.currentActor.events);
        setCurrentActor(data.currentActor);
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    setEventFilters({ ...eventFilters, name: { includes: searchTerm } });
  }, [searchTerm]);

  useEffect(() => {
    if (refetchEvents) {
      refetch();
      setRefetchEvents(false);
    }
  }, [refetchEvents]);

  useEffect(() => {
    window.localStorage.setItem('eventDisplaySettings', JSON.stringify(displaySettings));
    mapFilterParams();
    refetch();
  }, [eventFilters, displaySettings]);

  const filterOptions = [
    {
      key: 'filter-type-event-vendor-status',
      label: 'Status',
      children: [
        { key: 'inviteStatus-APPROVED', label: 'On Event', value: 'APPROVED' },
        { key: 'inviteStatus-REQUESTED', label: 'Request Sent', value: 'REQUESTED' },
        { key: 'inviteStatus-INVITED', label: 'Invite Pending', value: 'INVITED' },
        { key: 'inviteStatus-REJECTED', label: 'Invite Rejected', value: 'REJECTED' },
        { key: 'inviteStatus-REMOVED', label: 'Removed from Event', value: 'REMOVED' },
      ],
    },
  ];

  const filterItemTemplate = (node) => {
    if (node.key.startsWith('inviteStatus')) {
      if ((node.value === 'REJECTED' && !displaySettings.showRejected) || (node.value === 'REMOVED' && !displaySettings.showRemoved)) {
        return (
          <div className="flex items-center">
            <i className="pi pi-eye-slash mr-1" />
            <p className="italic">{node.label}</p>
          </div>
        );
      }

      return (
        <div className="flex items-center">
          <p>{node.label}</p>
        </div>
      );
    }

    return (
      <div className="flex items-center">
        <p>{node.label}</p>
      </div>
    );
  };

  if (error) { return showErrorToast(toastRef, 'Error loading events'); }
  if (!eventsData) { return null; }

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <p className="font-semibold text-xl text-gray-800">Events</p>
          <Button
            ref={filtersButtonRef}
            rounded
            text
            className="h-8 w-8 mx-1"
            icon="pi pi-filter"
            onClick={() => { setShowFilters(!showFilters); }}
          />
        </div>
        <div className="flex items-center">
          <IconField className="mr-2">
            <InputIcon className="pi pi-search" />
            <InputText placeholder="Search..." onInput={({ target }) => setSearchTerm(target.value)} />
          </IconField>
          <Button
            rounded
            className="h-8 w-8 mr-1 manage-display-settings-button"
            icon="pi pi-sliders-h"
            data-pr-tooltip="Manage Display Settings"
            data-pr-position="top"
            onClick={(e) => displaySettingsRef?.current?.toggle(e)}
          />
          <OverlayPanel ref={displaySettingsRef}>
            <ManageDisplaySettings
              displaySettings={displaySettings}
              setShowRejected={setShowRejected}
              setShowRemoved={setShowRemoved}
            />
          </OverlayPanel>
          <Tooltip target=".manage-display-settings-button" />
          <Button
            visible={canUpdateTemplates}
            rounded
            className="h-8 w-8 mr-1 manage-templates-button"
            icon="fa-regular fa-note-sticky"
            data-pr-tooltip="Manage Templates"
            data-pr-position="top"
            onClick={() => setShowManageTemplatesModal(true)}
          />
          <Tooltip target=".manage-templates-button" />
          {
            canCreateEvents && (
              <Button
                rounded
                className="h-8 w-8"
                icon="pi pi-plus"
                onClick={() => setShowAddNewEventModal(true)}
              />
            )
          }
        </div>
      </div>
      <Filters
        filtersButtonRef={filtersButtonRef}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        filterOptions={filterOptions}
        filterItemTemplate={filterItemTemplate}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        setFilters={setEventFilters}
        selectedKeysLocalStorageName="selectedEventFilters"
        filtersLocalStorageName="eventFilters"
      />
      <div className="content">
        {
          showNoMatchingErrorMessage ? (
            <p>No events match the selected filters. Double check your display settings if you can't find what you're looking for.</p>
          ) : (
            <EventsIndexTable
              eventsData={eventsData}
              paginationBounds={paginationBounds}
              setPaginationBounds={setPaginationBounds}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              refetch={refetch}
              pageSize={PAGE_SIZE}
            />
          )
        }
      </div>
      <ManageTemplatesModal
        eventTemplates={eventTemplates}
        refetchTemplates={refetchAndSetTemplates}
        show={showManageTemplatesModal}
        onHide={() => setShowManageTemplatesModal(false)}
      />
      <CreateEventModal
        eventTemplates={eventTemplates}
        show={showAddNewEventModal}
        showAddHost={currentActor.actorType !== 'HOST'}
        onHide={() => setShowAddNewEventModal(false)}
        refetchEvents={refetch}
        toastRef={toastRef}
      />
      <Toast ref={toastRef} position="bottom-left" style={{ zIndex: 1000 }} />
    </>
  );
}

export default Events;
