import gql from 'graphql-tag';
import TIME_BLOCK_FIELDS from 'graphql/fragments/timeBlock';

const EVENT_QUERY = gql`
  ${TIME_BLOCK_FIELDS}

  query Event($filters: EventFilterInput) {
    currentVendor {
      id
    }
    currentActor {
      id
      name
      events(filters: $filters) {
        edges {
          cursor
          node {
            id
            isUpdatableByCurrentActor
            timeZone
            name
            startDate
            hostActors {
              id
              name
              initials
              avatarUrl
            }
            timeline {
              id
              startTime
              endTime
              timeBlocks {
                ...TimeBlockFields
              }
            }
          }
        }
      }
    }
  }
`;

const EVENT_TEMPLATE_QUERY = gql`
  ${TIME_BLOCK_FIELDS}

  query EventTemplate($filters: EventFilterInput) {
    currentVendor {
      id
    }
    currentActor {
      id
      name
      eventTemplates(filters: $filters) {
        id
        isTemplate
        templateName
        isUpdatableByCurrentActor
        timeZone
        name
        startDate
        timeline {
          id
          startTime
          endTime
          timeBlocks {
            ...TimeBlockFields
          }
        }
      }
    }
  }
`;

const EVENT_UPDATE_MUTATION = gql`
  mutation EventUpdate($input: EventUpdateMutationInput!) {
    eventUpdate(input: $input) {
      event {
        id
        name
        templateName
        startDate
      }
    }
  }
`;

const EVENT_TEMPLATE_DELETE_MUTATION = gql`
  mutation DeleteEventTemplate($input: EventTemplateDeleteMutationInput!) {
    eventTemplateDelete(input: $input) {
      id
    }
  }
`;

export {
  EVENT_QUERY,
  EVENT_TEMPLATE_QUERY,
  EVENT_TEMPLATE_DELETE_MUTATION,
  EVENT_UPDATE_MUTATION,
};
