import gql from 'graphql-tag';
import UPLOADED_FILE_FIELDS from 'graphql/fragments/uploadedFile';
import TIME_BLOCK_FIELDS from 'graphql/fragments/timeBlock';

const TEMPLATES_FETCH_QUERY = gql`
  ${UPLOADED_FILE_FIELDS}
  ${TIME_BLOCK_FIELDS}

  query FetchTemplates {
    currentActor {
      id
      taskTemplates {
        id
        templateName
        title
        createdByActor {
          id
          name
          initials
          avatarUrl
        }
        lastUpdatedByActor {
          id
          name
          initials
          avatarUrl
        }
      }
      subtaskTemplates {
        id
        subtaskType
        templateName
        question {
          id
          body
          attachments {
            ...UploadedFileFields
          }
        }
        fileRequest {
          id
          fileRequested
          files {
            ...UploadedFileFields
          }
        }
        checklist {
          id
          title
          items {
            id
            body
            completed
          }
        }
        createdByActor {
          id
          name
          initials
          avatarUrl
        }
        lastUpdatedByActor {
          id
          name
          initials
          avatarUrl
        }
        timeBlock {
          ...TimeBlockFields
        }
      }
    }
  }
`;

const TASK_TEMPLATE_QUERY = gql`
  ${UPLOADED_FILE_FIELDS}
  ${TIME_BLOCK_FIELDS}

  query FetchTemplate($taskFilters: TaskFilterInput) {
    currentActor {
      id
      taskTemplates(filters: $taskFilters) {
        id
        isTemplate
        templateName
        requireReview
        title
        description
        isUpdatableByCurrentActor
        attachments {
          ...UploadedFileFields
        }
        createdByActor {
          id
          name
          initials
          avatarUrl
          vendor {
            id
            name
            initials
          }
        }
        lastUpdatedByActor {
          id
          name
          initials
          avatarUrl
          vendor {
            id
            name
            initials
          }
        }
        assignedToActor {
          id
          name
          initials
          avatarUrl
          vendor {
            id
            name
            initials
            categories
          }
        }
        labels {
          id
          name
          colorHexCode
        }
        participantActors {
          id
          uuid
          name
          initials
          avatarUrl
          vendor {
            id
            name
            initials
          }
        }
        subtasks {
          id
          subtaskType
          question {
            id
            body
            attachments {
              ...UploadedFileFields
            }
          }
          fileRequest {
            id
            fileRequested
            files {
              ...UploadedFileFields
            }
          }
          checklist {
            id
            title
            items {
              id
              body
              completed
            }
          }
          timeBlock {
            ...TimeBlockFields
          }
        }
      }
    }
  }
`;

const LABELS_FETCH_QUERY = gql`
  query FetchLabels {
    currentActor {
      id
      taskLabels {
        id
        name
        colorHexCode
        createdByActor {
          id
          name
          initials
          avatarUrl
        }
        lastUpdatedByActor {
          id
          initials
          avatarUrl
          name
        }
      }
    }
  }
`;

const TASKS_QUERY = gql`
  query Tasks($taskFilters: TaskFilterInput) {
    currentActor {
      id
      uuid
      initials
      name
      avatarUrl
      unreadTaskMentionsCount
      tasks(filters: $taskFilters) {
        edges {
          node {
            id
            archived
            title
            status
            overdue
            dueDate
            isUpdatableByCurrentActor
            position
            event {
              id
              name
              startDate
              endDate
            }
            labels {
              id
              name
              colorHexCode
            }
            assignedToActor {
              id
              name
              initials
              avatarUrl
              actorType
              vendor {
                id
                name
                initials
                categories
              }
            }
            createdByActor {
              id
              name
              avatarUrl
              initials
              vendor {
                id
                name
                initials
              }
            }
          }
        }
      }
    }
    currentVendor {
      id
      name
      taskActors {
        edges {
          node {
            id
            name
            uuid
            actorType
            avatarUrl
            initials
          }
        }
      }
    }
  }
`;

const TASK_QUERY = gql`
  ${UPLOADED_FILE_FIELDS}
  ${TIME_BLOCK_FIELDS}

  query Task($taskFilters: TaskFilterInput, $mentionFilters: MentionFilterInput) {
    currentActor {
      id
      name
      tasks(filters: $taskFilters) {
        edges {
          node {
            id
            isTemplate
            isEventTemplate
            templateName
            title
            description
            archived
            activityLog {
              id
              action
              updatedByActor {
                id
                name
                initials
                avatarUrl
              }
              updatedAt
              changes {
                id
                updatableType
                updatedFrom
                updatedTo
                added
                removed
                subjectItem {
                  id
                  updatableType
                  value
                }
                parentItem {
                  id
                  updatableType
                  value
                }
              }
            }
            attachments {
              ...UploadedFileFields
            }
            labels {
              id
              name
              colorHexCode
            }
            overdue
            isUpdatableByCurrentActor
            status
            dueDate
            createdAt
            lastUpdatedAt
            event {
              id
              name
              startDate
              endDate
              timeline {
                id
                startTime
                timeBlocks {
                  ...TimeBlockFields
                }
              }
            }
            comments {
              id
              body
              updatable
              attachments {
                ...UploadedFileFields
              }
              mentions(filters: $mentionFilters) {
                id
                read
                mentionedActor {
                  id
                }
              }
              updatedAt
              createdByActor {
                id
                name
                avatarUrl
                initials
                vendor {
                  id
                  name
                  initials
                }
              }
            }
            subtasks {
              id
              completed
              subtaskType
              timeBlock {
                ...TimeBlockFields
              }
              question {
                id
                body
                attachments {
                  ...UploadedFileFields
                }
                mentions(filters: $mentionFilters) {
                  id
                  read
                  mentionedActor {
                    id
                  }
                }
                answers {
                  id
                  body
                  updatable
                  attachments {
                    ...UploadedFileFields
                  }
                  mentions(filters: $mentionFilters) {
                    id
                    read
                    mentionedActor {
                      id
                    }
                  }
                  updatedAt
                  createdByActor {
                    id
                    avatarUrl
                    initials
                    name
                    vendor {
                      id
                      name
                      initials
                    }
                  }
                }
              }
              fileRequest {
                id
                fileRequested
                files {
                  ...UploadedFileFields
                }
              }
              checklist {
                id
                title
                items {
                  id
                  body
                  completed
                }
              }
            }
            lastUpdatedByActor {
              id
              name
              initials
              avatarUrl
              vendor {
                id
                name
                initials
              }
            }
            assignedByActor {
              id
              name
              vendor {
                id
                name
                initials
              }
            }
            assignedToActor {
              id
              name
              initials
              avatarUrl
              vendor {
                id
                name
                initials
                categories
              }
            }
            createdByActor {
              id
              name
              avatarUrl
              initials
              vendor {
                id
                name
                initials
              }
            }
            participantActors {
              id
              uuid
              name
              initials
              avatarUrl
              vendor {
                id
                name
                initials
              }
            }
          }
        }
      }
    }
  }
`;

const EVENT_TEMPLATE_EVENT_VENDORS_QUERY = gql`
  query EventTemplateVendors($eventFilters: EventFilterInput) {
    currentActor {
      id
      uuid
      initials
      name
      avatarUrl
      eventTemplates(filters: $eventFilters) {
        id
        eventVendors {
          id
          categories
          vendor {
            id
            name
            initials
            avatarUrl
          }
          actors {
            id
            avatarUrl
            initials
            actorType
            name
          }
          defaultTaskAssigneeActor {
            id
            uuid
            name
            initials
            avatarUrl
            uuid
          }
        }
      }
    }
  }
`;

const EVENT_VENDORS_QUERY = gql`
  query EventVendors($eventFilters: EventFilterInput, $eventHostActorFilters: EventHostActorFilterInput) {
    currentActor {
      id
      uuid
      initials
      name
      avatarUrl
      events(filters: $eventFilters) {
        edges {
          node {
            id
            eventHostActors(filters: $eventHostActorFilters) {
              id
              status
              hostActor {
                id
                name
                initials
                avatarUrl
                actorType
                uuid
              }
            }
            eventVendors {
              id
              categories
              vendor {
                id
                name
                initials
                avatarUrl
              }
              actors {
                id
                avatarUrl
                initials
                actorType
                name
              }
              defaultTaskAssigneeActor {
                id
                uuid
                name
                initials
                avatarUrl
                uuid
              }
            }
          }
        }
      }
    }
  }
`;

const DELETE_TASK_MUTATION = gql`
  mutation DeleteTask($input: TaskDeleteMutationInput!) {
    taskDelete(input: $input) {
      id
    }
  }
`;

export {
  EVENT_VENDORS_QUERY,
  EVENT_TEMPLATE_EVENT_VENDORS_QUERY,
  TASKS_QUERY,
  TASK_QUERY,
  TASK_TEMPLATE_QUERY,
  TEMPLATES_FETCH_QUERY,
  LABELS_FETCH_QUERY,
  DELETE_TASK_MUTATION,
};
