import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMutation } from '@apollo/react-hooks';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';
import { Tooltip } from 'primereact/tooltip';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { confirmDialog } from 'primereact/confirmdialog';
import { classNames } from 'primereact/utils';
import map from 'lodash/map';
import compact from 'lodash/compact';
import last from 'lodash/last';
import kebabCase from 'lodash/kebabCase';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';
import moment from 'moment';

import MultiSelect from 'components/Form/MultiSelect';
import DateInput from 'components/Form/DateInput';
import EditableText from 'components/Form/EditableFields/Text';
import EditableTextArea from 'components/Form/EditableFields/TextArea';
import { showErrorToast } from 'utils/toastUtils';
import { actorItemTemplate } from 'utils/formUtils';
import AvatarWithName from 'components/AvatarWithName';
import { mergeClassNames, cursorClassName } from 'utils/styleUtils';
import Footer from './Footer';
import Labels from '../Labels';
import CompleteSubtaskForm from './CompleteSubtaskForm';
import PromptToComplete from './PromptToComplete';
import Status from './EditableItems/Status';
import Assignee from './EditableItems/Assignee';
import { TASK_ARCHIVE_MUTATION, TASK_UNARCHIVE_MUTATION, TASK_DELETE_MUTATION } from './graphql';

function CompleteTaskForm({
  task,
  updateTaskMutation,
  subtasks,
  attachments,
  removeEmptySubtasks,
  labels,
  actorOptions,
  refetch,
  hideModal,
  hideSelectTimeBlockModal,
  control,
  setValue,
  getValues,
  watch,
  toastRef,
  currentActor,
  eventVendors,
  currentVendorName,
}) {
  const {
    id,
    isTemplate,
    isEventTemplate,
    participantActors,
    assignedToActor,
    status,
    dueDate,
    createdByActor,
    lastUpdatedByActor,
    createdAt,
    lastUpdatedAt,
    isUpdatableByCurrentActor,
  } = task;
  const [isEditingTemplateName, setIsEditingTemplateName] = useState(false);
  const [hasMappedActors, setHasMappedActors] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isEditingStatus, setIsEditingStatus] = useState(false);
  const [isEditingAssignee, setIsEditingAssignee] = useState(false);
  const [isEditingLabels, setIsEditingLabels] = useState(false);
  const [isEditingDueDate, setIsEditingDueDate] = useState(false);
  const [showPromptToComplete, setShowPromptToComplete] = useState(false);
  const [subtaskUpdated, setSubtaskUpdated] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const isEditingSidebarItems = () => isEditingStatus || isEditingAssignee || isEditingDueDate || isEditingLabels;
  const { ref, inView } = useInView();

  useEffect(() => {
    setHasMappedActors(false);
  }, [actorOptions]);

  useEffect(() => {
    setValue('assignedToActorId', assignedToActor?.id);
  }, [assignedToActor]);

  useEffect(() => {
    if (!subtaskUpdated) { return; }

    const subtasksCompleted = !subtasks.find((subtask) => !subtask.completed);

    if (subtasksCompleted && status !== 'COMPLETED') {
      setShowPromptToComplete(true);
    }
  }, [subtasks]);

  useEffect(() => {
    setValue('dueDate', dueDate ? new Date(dueDate) : null);
  }, [dueDate]);

  useEffect(() => {
    setValue('participantActorIds', map(participantActors || [], 'id'));
  }, [participantActors]);

  useEffect(() => {
    setValue('labelIds', map(task.labels || [], 'id'));
  }, [task.labels]);

  const [deleteTaskMutation] = useMutation(TASK_DELETE_MUTATION, {
    onCompleted: () => { hideModal(); },
    onError: (error) => {
      const { graphQLErrors, message } = error;

      if (message) {
        showErrorToast(toastRef, error.message);
      } else {
        graphQLErrors.map(({ message: gqlError }) => (
          showErrorToast(toastRef, `Error deleting task: ${gqlError}`)
        ));
      }
    },
  });

  const [archiveTaskMutation] = useMutation(TASK_ARCHIVE_MUTATION, {
    onCompleted: () => { refetch(); },
    onError: (error) => {
      const { graphQLErrors, message } = error;

      if (message) {
        showErrorToast(toastRef, error.message);
      } else {
        graphQLErrors.map(({ message: gqlError }) => (
          showErrorToast(toastRef, `Error archiving task: ${gqlError}`)
        ));
      }
    },
  });

  const [unarchiveTaskMutation] = useMutation(TASK_UNARCHIVE_MUTATION, {
    onCompleted: () => { refetch(); },
    onError: (error) => {
      const { graphQLErrors, message } = error;

      if (message) {
        showErrorToast(toastRef, error.message);
      } else {
        graphQLErrors.map(({ message: gqlError }) => (
          showErrorToast(toastRef, `Error unarchiving task: ${gqlError}`)
        ));
      }
    },
  });

  const updateTask = async (values) => {
    updateTaskMutation(values);
    setIsEditingDescription(false);
    setIsEditingAssignee(false);
    setIsEditingDueDate(false);
    setIsEditingLabels(false);
    setIsEditingStatus(false);
  };

  const externalVendorTaskActor = (vendorId) => (
    last(orderBy(task.participantActors.filter((actor) => actor.vendor?.id === vendorId), 'id'))
    || (eventVendors || []).find((eventVendor) => eventVendor.vendor.id === vendorId)?.defaultTaskAssigneeActor
  );

  const mapActorOptions = () => {
    if (hasMappedActors) { return actorOptions; }

    const otherVendorOption = actorOptions.find((option) => option.value === 'other-vendors');
    const mappedItems = compact(otherVendorOption?.items?.map((eventVendorOption) => {
      const { vendor } = eventVendorOption;
      const actor = externalVendorTaskActor(vendor.id);

      if (!actor) { return null; }

      return {
        ...eventVendorOption,
        label: vendor.name,
        value: actor.id,
        id: actor.id,
        name: actor.name,
        uuid: actor.uuid,
        initials: vendor.name[0],
        avatarUrl: vendor.avatarUrl,
      };
    }));

    if (otherVendorOption) {
      otherVendorOption.items = mappedItems;
    }

    setHasMappedActors(true);
    return actorOptions;
  };

  const mappedActorOptions = mapActorOptions().filter((group) => group.items.length);

  const actorItem = ({
    actor, label, updateAction, tooltip,
  }) => {
    /* eslint-disable max-len */
    const unassignedClassNames = 'text-xs font-semibold border p-1 rounded-md border-bluegray-500 [&:not(:hover)]:bg-bluegray-500 [&:not(:hover)]:text-white hover:bg-white hover:text-bluegray-500';
    /* eslint-enable max-len */

    const avatarBadgeClassNames = classNames(
      'justify-end',
      'font-semibold',
      'border-bluegray-500',
      'text-xs',
      'border',
      'py-1',
      'px-2',
      'mt-1',
      'mb-3',
      'rounded-md',
      '[&:not(:hover)]:bg-bluegray-500 [&:not(:hover)]:text-white',
      { 'bg-bluegray-500 text-white': !updateAction },
      { 'hover:bg-white hover:text-bluegray-500': updateAction },
    );

    return (
      <div className={`${cursorClassName(updateAction)}`}>
        <div className="flex items-center justify-end">
          <i className="pi pi-user text-xs mr-1" />
          <p className="text-bluegray-500">{ label }</p>
          {
            tooltip && (
              <>
                <Tooltip target={`.${kebabCase(label)}-item`} />
                <i
                  className={`pi pi-info-circle ${kebabCase(label)}-item text-xs ml-1`}
                  data-pr-tooltip={tooltip}
                  data-pr-position="top"
                />
              </>
            )
          }
        </div>
        <div className="flex items-center justify-end text-xs">
          {
            actor ? (
              <AvatarWithName
                id={actor.id}
                avatarUrl={actor.avatarUrl}
                initials={actor.initials}
                text={actor.name}
                subtext={actor.vendor?.name}
                onClick={updateAction}
                onBlur={updateAction}
                className={avatarBadgeClassNames}
                avatarClassName="border mr-1 text-xs bg-white text-bluegray-500 border-bluegray-500"
                size="small"
              />
            ) : (
              <Tag
                value="Unassigned"
                onClick={updateAction}
                className={unassignedClassNames}
              />
            )
          }
        </div>
      </div>
    );
  };

  const taskInfoItem = ({
    infoName, infoValue, iconName, tooltip = null, badgeClass = '', badgeAction = null,
  }) => {
    const badgeClasses = classNames(
      badgeClass,
      'rounded-md',
      'border',
      'font-semibold',
      'border-bluegray-500',
      '[&:not(:hover)]:bg-bluegray-500 [&:not(:hover)]:text-white',
      `task-info-item-badge-${kebabCase(infoName)}`,
      { 'cursor-pointer hover:bg-white hover:text-bluegray-500': badgeAction },
      'max-w-max',
      'mt-1',
      'mb-3',
      'self-end',
      'float-end',
    );

    let action = badgeAction;
    action ||= () => {};

    return (
      <>
        <Tooltip target={`.task-info-item-badge-${kebabCase(infoName)}`} />
        <div className="flex items-center justify-end">
          <i className={`pi pi-${iconName} text-xs mr-1`} /><p className="text-bluegray-500">{infoName}</p>
        </div>
        <div className="flex items-center justify-end">
          <Badge
            className={badgeClasses}
            onClick={action}
            value={infoValue}
            data-pr-tooltip={tooltip}
            data-pr-position="top"
          />
        </div>
      </>
    );
  };

  const mappedLabels = () => {
    const updateAction = () => setIsEditingLabels(true);
    /* eslint-disable max-len */
    const labelClassNames = 'border group-hover:border-bluegray-500 group-hover:bg-white group-hover:text-bluegray-500 font-semibold';
    const noneAssignedClassNames = 'text-xs font-semibold border p-1 rounded-md border-bluegray-500 [&:not(:hover)]:bg-bluegray-500 [&:not(:hover)]:text-white hover:bg-white hover:text-bluegray-500';
    /* eslint-enable max-len */

    return (
      <>
        <div className="flex items-center justify-end">
          <i className="pi pi-tag text-xs mr-1" />
          <p className="text-bluegray-500 mt-0 mr-1">Labels</p>
          <i
            className="pi pi-info-circle text-xs mr-1 complete-task-form-labels-item-tooltip-icon"
            data-pr-tooltip={`Only viewable within ${currentVendorName}`}
            data-pr-position="top"
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="flex items-center justify-end flex-wrap mt-1 mb-3 cursor-pointer group">
          {
            task.labels.length ? (
              <Labels
                labels={task.labels}
                onClick={updateAction}
                clickable
                labelTotalTagClassName={labelClassNames}
                inlineLabelTagClassName={labelClassNames}
              />
            ) : (
              <Tag
                value="None assigned"
                onClick={updateAction}
                className={noneAssignedClassNames}
              />
            )
          }
        </div>
        <Tooltip target=".complete-task-form-labels-item-tooltip-icon" />
      </>
    );
  };

  const labelOptions = (
    reduce(labels, (mapped, label) => {
      mapped.push({ label: label.name, value: label.id, colorHexCode: label.colorHexCode });
      return mapped;
    }, [])
  );

  const labelOptionTemplate = (label) => <Tag style={{ backgroundColor: label.colorHexCode }} value={label.label} />;

  const selectedLabelTemplate = (labelId) => {
    if (!labels.length) { return <p className="text-bluegray-500">No Labels</p>; }
    if (!labelId) { return <p className="text-bluegray-500">Select Labels</p>; }

    const selectedLabel = labels.find((label) => label.id === labelId);
    return <Tag className="m-1" style={{ backgroundColor: selectedLabel.colorHexCode }} value={selectedLabel.name} />;
  };

  const labelsItem = () => {
    if (isEditingLabels) {
      return (
        <>
          <div className="flex items-center justify-end mb-3">
            <i className="pi pi-tag text-xs mr-1" />
            <p className="text-bluegray-500 mt-0 mr-1">Labels</p>
            <i
              className="pi pi-info-circle text-xs mr-1 complete-task-form-labels-item-tooltip-icon"
              data-pr-tooltip={`Only viewable within ${currentVendorName}`}
              data-pr-position="top"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="flex items-center justify-end mb-3 cursor-pointer">
            <MultiSelect
              control={control}
              name="labelIds"
              className="p-0 complete-task-form-labels-select w-5/6"
              options={labelOptions}
              inputProps={{
                placeholder: 'Select labels',
                itemTemplate: (label) => labelOptionTemplate(label),
                selectedItemTemplate: (labelId) => selectedLabelTemplate(labelId),
              }}
            />
            <div className="flex flex-col ml-1">
              <i
                className="pi pi-check text-xs cursor-pointer"
                onClick={() => updateTask({ id, labelIds: watch('labelIds') })}
              />
              <i className="pi pi-times text-xs cursor-pointer" onClick={() => setIsEditingLabels(false)} />
            </div>
          </div>
        </>
      );
    }

    return mappedLabels();
  };

  const participantsItem = () => (
    <>
      <div className="flex items-center justify-end">
        <i className="pi pi-users text-xs mr-1" /><p className="text-bluegray-500">Participants</p>
      </div>
      <div className="flex items-center justify-end w-full flex-wrap mb-3 mt-1">
        <div className="grid grid-cols-5 rtl -mr-1">
          {
            task.participantActors.length ? (
              task.participantActors.map((participantActor) => {
                const particpantActorName = participantActor.name;
                const tooltip = participantActor.vendor
                  ? `${particpantActorName} (${participantActor.vendor.name})`
                  : particpantActorName;

                const participantActorAvatarClassNames = classNames(
                  `participant-avatar-tooltip-${participantActor.id}`,
                  'font-semibold',
                  'bg-bluegray-500',
                  'text-white',
                  'rounded-full',
                  'p-0',
                  'mt-1',
                  'mr-1',
                );

                return (
                  <span key={`${participantActor.id}-participant`}>
                    <Tooltip key={`${participantActor.id}-avatar-tooltip`} target={`.participant-avatar-tooltip-${participantActor.id}`} />
                    <Avatar
                      key={`${participantActor.id}-avatar`}
                      image={participantActor.avatarUrl}
                      label={participantActor.initials}
                      shape="circle"
                      className={participantActorAvatarClassNames}
                      data-pr-tooltip={tooltip}
                      data-pr-position="top"
                    />
                  </span>
                );
              })
            ) : (
              <p className="text-sm">None assigned</p>
            )
          }
        </div>
      </div>
    </>
  );

  const dueDateItem = () => {
    if (isEditingDueDate) {
      return (
        <>
          <div className="flex items-center justify-end">
            <i className="pi pi-clock text-xs mr-1" /><p className="text-bluegray-500">Due Date</p>
          </div>
          <div className="flex items-center justify-end w-full cursor-pointer">
            <DateInput
              control={control}
              className="p-0 m-0 w-unset"
              labelClassName="-mt-2"
              showClear
              name="dueDate"
              minDate={new Date()}
              required={false}
              inputProps={{
                onChange: ({ value }) => updateTask({ id, dueDate: value }),
              }}
            />
            <i className="pi pi-times text-xs ml-1 cursor-pointer" onClick={() => setIsEditingDueDate(false)} />
          </div>
        </>
      );
    }

    const badgeClass = classNames(
      'rounded-md',
      'font-semibold',
      '[&:not(:hover)]:bg-bluegray-500',
      '[&:not(:hover)]:text-white',
      { 'status-badge status-OVERDUE-bg no-hover': task.overdue },
    );

    return (
      taskInfoItem({
        infoName: 'Due Date',
        infoValue: dueDate ? moment(dueDate).format('M/D/YY') : 'N/A',
        iconName: 'clock',
        badgeClass,
        badgeAction: isUpdatableByCurrentActor ? () => setIsEditingDueDate(true) : null,
      })
    );
  };

  const deleteButton = () => {
    const objectType = isTemplate ? 'template' : 'task';
    const confirmDelete = () => confirmDialog({
      className: 'xl:w-3/12',
      message: `Are you sure you want to permanently delete this ${objectType}? Deleted ${objectType}s cannot be recovered.`,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteTaskMutation({ variables: { input: { id } } }),
    });

    return (
      <Button
        onClick={confirmDelete}
        type="button"
        icon="pi pi-trash"
        size="small"
        label="Delete"
        severity="danger"
        outlined
        aria-label="Delete"
      />
    );
  };

  const unarchiveButton = () => (
    <Button
      onClick={() => unarchiveTaskMutation({ variables: { input: { id } } })}
      className="mb-3"
      type="button"
      icon="pi pi-undo"
      size="small"
      label="Unarchive"
      severity="success"
      outlined
      aria-label="Unarchive"
    />
  );

  const unarchiveAndDeleteButtons = () => (
    <div className="flex flex-col">
      {unarchiveButton()}
      {deleteButton()}
    </div>
  );

  const archiveButton = () => {
    const confirmArchive = () => confirmDialog({
      className: 'xl:w-3/12',
      message: 'Are you sure you want to archive this task? Archived tasks can be recovered for 30 days, then they will be permanently deleted.',
      header: 'Archive Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => archiveTaskMutation({ variables: { input: { id } } }),
    });

    return (
      <Button
        type="button"
        onClick={confirmArchive}
        icon="pi pi-trash"
        size="small"
        label="Archive"
        severity="warning"
        outlined
        aria-label="Archive"
      />
    );
  };

  const statusItem = () => (
    <Status
      status={status}
      isUpdatableByCurrentActor={isUpdatableByCurrentActor}
      isEditing={isEditingStatus}
      setIsEditing={setIsEditingStatus}
      updateTask={updateTask}
      control={control}
      setValue={setValue}
    />
  );

  const assigneeItem = () => (
    <Assignee
      actorItem={actorItem}
      taskId={id}
      actorItemTemplate={actorItemTemplate}
      actorOptions={actorOptions}
      assignedToActorId={watch('assignedToActorId')}
      isEditing={isEditingAssignee}
      setIsEditing={setIsEditingAssignee}
      updateTask={updateTask}
      control={control}
      setValue={setValue}
    />
  );

  const templateNameItem = () => (
    isTemplate && (
      <div className="mb-3">
        <div className="flex items-center justify-end">
          <i className="pi pi-folder text-xs mr-1" /><p className="text-bluegray-500">Template Name</p>
        </div>
        <div className="flex items-center justify-end text-sm">
          <EditableText
            className="flex items-center justify-end w-full"
            text={getValues('templateName')}
            name="templateName"
            updatable={isUpdatableByCurrentActor}
            onUpdate={updateTask}
            isEditing={isEditingTemplateName}
            setIsEditing={setIsEditingTemplateName}
            control={control}
            setValue={setValue}
            getValues={getValues}
          />
          {
            isUpdatableByCurrentActor && (
              <i className="pi pi-pencil text-xs ml-1 cursor-pointer" onClick={() => setIsEditingTemplateName(true)} />
            )
          }
        </div>
      </div>
    )
  );

  const archiveButtons = () => (task.archived ? unarchiveAndDeleteButtons() : archiveButton());

  const archiveItem = () => (
    <div className="flex justify-end w-full mt-4">
      {
        (isTemplate || isEventTemplate) ? (
          deleteButton()
        ) : (
          archiveButtons()
        )
      }
    </div>
  );

  const sidebarItems = (className = '') => {
    const sidebarClassNames = mergeClassNames(classNames(
      'flex',
      'flex-col',
      'w-full',
      'complete-task-form--mobile-sidebar',
      'lg:col-span-2',
      'pl-4',
      className,
    ));

    return (
      <form className={sidebarClassNames}>
        { templateNameItem() }
        { !isTemplate && !isEventTemplate && statusItem() }
        { labelsItem() }
        { assigneeItem() }
        { !isTemplate && !isEventTemplate && dueDateItem() }
        { actorItem({ actor: createdByActor, label: 'Created By' }) }
        { actorItem({ actor: lastUpdatedByActor, label: 'Last Updated By' }) }
        {
          taskInfoItem({
            infoName: 'Created At',
            infoValue: moment(createdAt).format('M/D/YY'),
            tooltip: moment(createdAt).format('h:mma'),
            iconName: 'clock',
          })
        }
        {
          taskInfoItem({
            infoName: 'Last Updated At',
            infoValue: moment(lastUpdatedAt).format('M/D/YY'),
            tooltip: moment(lastUpdatedByActor).format('h:mma'),
            iconName: 'clock',
          })
        }
        { participantsItem() }
        { archiveItem() }
      </form>
    );
  };

  const sidebarClasses = classNames(
    'pr-6',
    'min-w-max',
    'lg:hidden',
  );

  const sidebar = () => (
    <>
      <Sidebar
        modal={false}
        appendTo={document.getElementById('complete-task-form-modal')}
        visible={sidebarVisible || isEditingSidebarItems()}
        className={sidebarClasses}
        maskClassName="xxs:block w-full flex justify-end lg:hidden"
        position="right"
        onHide={() => setSidebarVisible(false)}
      >
        {sidebarItems()}
      </Sidebar>
      {sidebarItems(['xxs:hidden', 'lg:block'])}
    </>
  );

  const mapMentionables = () => (
    mappedActorOptions.flatMap((option) => {
      const actors = option.items;

      return actors.map(({
        label, uuid, initials, avatarUrl, name, vendor,
      }) => {
        let mappedValue = name;
        if (vendor?.name) { mappedValue += ` (${vendor.name})`; }

        return ({
          mention: uuid,
          label,
          value: mappedValue,
          group: option.label,
          initials,
          avatarUrl,
        });
      });
    })
  );
  const mentionables = mapMentionables();

  return (
    <>
      <div className="complete-task-form flex w-full grid grid-cols-12 p-0">
        <div className="xxs:col-span-12 lg:col-span-10">
          <div className="flex items-start justify-between w-full overflow-visible pb-4 pt-2">
            <EditableTextArea
              required={false}
              mentionsDisabled={false}
              mentionables={mentionables}
              attachmentsDisabled={false}
              attachments={attachments}
              name="description"
              placeholder="Add task description..."
              updatable={isUpdatableByCurrentActor}
              onUpdate={updateTask}
              isEditing={isEditingDescription}
              setIsEditing={setIsEditingDescription}
              control={control}
              setValue={setValue}
              getValues={getValues}
            />
            <Button
              icon="pi pi-info"
              rounded
              outlined
              severity="bluegray-500"
              className="ml-2 lg:hidden h-8 w-8"
              onClick={() => setSidebarVisible(true)}
            />
          </div>
          <div className="mt-4">
            {
              subtasks.map((subtask) => (
                <div
                  ref={ref}
                  key={`${subtask.id}-subtask-card`}
                  className="p-0 z-10 border-solid border-2 border-bluegray-500-200 mt-2 rounded my-4"
                >
                  <CompleteSubtaskForm
                    key={`${subtask.id}-subtask-form`}
                    inView={inView}
                    subtask={subtask}
                    setSubtaskUpdated={setSubtaskUpdated}
                    onRemoveEmptySubtask={removeEmptySubtasks}
                    task={task}
                    mentionables={mentionables}
                    actorOptions={mappedActorOptions}
                    currentActor={currentActor}
                    onCreate={hideSelectTimeBlockModal}
                    refetch={refetch}
                    toastRef={toastRef}
                    hideModal={hideModal}
                  />
                </div>
              ))
            }
          </div>
        </div>
        { sidebar() }
      </div>
      {
        !isTemplate && !isEventTemplate && (
          <div className="grid grid-cols-12 mt-6">
            <Footer
              actorOptions={mappedActorOptions}
              mentionables={mentionables}
              eventVendors={eventVendors}
              task={task}
              labels={labels}
              currentActor={currentActor}
              refetch={refetch}
            />
            <PromptToComplete show={showPromptToComplete} setShow={setShowPromptToComplete} taskId={id} updateTask={updateTask} />
          </div>
        )
      }
    </>
  );
}

export default CompleteTaskForm;
