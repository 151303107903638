import React from 'react';
import { Dialog } from 'primereact/dialog';
import ManageTemplatesForm from '.';

function ManageTemplatesModal({
  actorOptions,
  taskTemplates,
  subtaskTemplates,
  refetchTemplates,
  show,
  onHide,
}) {
  return (
    <Dialog
      className="manage-templates-modal xl:w-7/12"
      header="Manage Templates"
      visible={show}
      onHide={onHide}
      draggable={false}
      maximizable
      dismissableMask
      resizable
    >
      <ManageTemplatesForm
        taskTemplates={taskTemplates}
        subtaskTemplates={subtaskTemplates}
        actorOptions={actorOptions}
        refetchTemplates={refetchTemplates}
      />
    </Dialog>
  );
}

export default ManageTemplatesModal;
