import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Avatar } from 'primereact/avatar';
import { confirmDialog } from 'primereact/confirmdialog';

import EmailInput from 'components/Form/EmailInput';
import TextInput from 'components/Form/TextInput';
import PhoneNumberInput from 'components/Form/PhoneNumberInput';
import MultiSelectInput from 'components/Form/MultiSelect';
import InstagramHandleInput from 'components/Form/InstagramHandleInput';
import { mapSelectOptions } from 'utils/formUtils';
import {
  INVITE_TEAM_MEMBER_ACTOR_MUTATION,
  DEACTIVATE_TEAM_MEMBER_ACTOR_MUTATION,
  UPDATE_ACTOR_MUTATION,
  VENDOR_ROLES_QUERY,
  CREATE_TEAM_MEMBER_ACTOR_MUTATION,
} from './graphql';

function CreateOrUpdateTeamMemberForm({
  teamMemberActor, refetchTeamMembersAndGroups, hideModal, toastRef, currentVendorName,
}) {
  const [vendorRoles, setVendorRoles] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [shouldHideModalAndRefresh, setShouldHideModalAndRefresh] = useState(false);

  const [updateTeamMemberActorMutation] = useMutation(UPDATE_ACTOR_MUTATION, {
    onCompleted: async () => {
      if (shouldHideModalAndRefresh) {
        hideModal();
        refetchTeamMembersAndGroups();
      }
      showSuccessToast(toastRef, 'Successfully updated team member');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  }); const [actor, setActor] = useState(teamMemberActor);

  useEffect(() => {
    if (actor?.id && avatar) {
      updateTeamMemberActorMutation({ variables: { input: { id: actor.id, avatar } } });
    }
  }, [avatar]);

  const setUploadedAvatar = (file) => {
    setAvatar(file);
    setShouldHideModalAndRefresh(false);
  };

  const {
    control, handleSubmit, setValue,
  } = useForm({
    defaultValues: {
      firstName: actor.firstName,
      lastName: actor.lastName,
      email: actor.email,
      phoneNumber: actor.phoneNumber,
      instagramHandle: actor.instagramHandle,
    },
  });

  const [inviteTeamMemberActorMutation] = useMutation(INVITE_TEAM_MEMBER_ACTOR_MUTATION, {
    onCompleted: async () => {
      showSuccessToast(toastRef, 'Sent invite!');
      await refetchTeamMembersAndGroups();
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const confirmSendInvite = () => {
    const userName = actor.name !== '' ? actor.name : 'this team member';
    const message = `Send invite to ${userName}?`;

    return confirmDialog({
      className: 'xl:w-3/12',
      message,
      header: 'Send Invite Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => inviteTeamMemberActorMutation({ variables: { input: { id: actor.id } } }),
      reject: () => refetchTeamMembersAndGroups(),
    });
  };

  useEffect(() => {
    if (actor !== teamMemberActor) { confirmSendInvite(); }
  }, [actor]);

  const { teamMemberUser = {} } = actor;

  const [deactivateTeamMemberActorMutation] = useMutation(DEACTIVATE_TEAM_MEMBER_ACTOR_MUTATION, {
    onCompleted: async () => {
      await refetchTeamMembersAndGroups();
      showSuccessToast(toastRef, 'Successfully deactivated team member');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const deactivateButton = () => {
    const confirmDeactivate = () => {
      const userName = actor.name !== '' ? actor.name : 'this team member';
      const message = teamMemberUser.isCurrentUser ? (
        `Are you sure you want to deactivate yourself on ${currentVendorName}? \nYou will immediately lose access.`
      ) : `Are you sure you want to deactivate ${userName} on ${currentVendorName}?`;

      return confirmDialog({
        className: 'xl:w-3/12',
        message,
        header: 'Deactivate Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => deactivateTeamMemberActorMutation({ variables: { input: { id: actor.id } } }),
      });
    };

    return (
      <Button
        key={`${actor.id}-deactivate`}
        onClick={confirmDeactivate}
        className="xxs:w-full md:w-min text-nowrap bg-white border-red-500 text-red-500 mt-2"
        size="small"
        icon="pi pi-trash"
        label="Deactivate"
        type="button"
      />
    );
  };

  const {
    vendorRolesLoading, vendorRolesError,
  } = useQuery(
    VENDOR_ROLES_QUERY,
    {
      onCompleted: (data) => {
        setVendorRoles(mapSelectOptions({ data: data.currentVendor.teamMemberActorRoles }));
        setValue('roleIds', (actor.roles || []).map((role) => role.id));
      },
    },
  );

  if (vendorRolesLoading || vendorRolesError) { return null; }

  const [createTeamMemberActorMutation] = useMutation(CREATE_TEAM_MEMBER_ACTOR_MUTATION, {
    onCompleted: async ({ teamMemberActorCreate }) => {
      hideModal();
      setActor(teamMemberActorCreate.teamMemberActor);
      showSuccessToast(toastRef, 'Successfully created team member');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const onSubmit = (values) => {
    setShouldHideModalAndRefresh(true);

    if (actor?.id) {
      updateTeamMemberActorMutation({ variables: { input: { ...values, id: actor.id, avatar } } });
    } else {
      createTeamMemberActorMutation({ variables: { input: { ...values, avatar } } });
    }
  };

  const accountHolderChip = () => {
    if (!actor.id) { return null; }

    if (teamMemberUser.accountHolder) {
      return (
        <div className="flex w-full justify-center">
          <Button
            key={`${teamMemberUser.id}-account-holder-chip`}
            severity="success"
            icon="pi pi-check"
            label="Account Holder"
            text
            className="account-holder-chip text-xs bg-none cursor-default"
          />
        </div>
      );
    }

    return (
      <div className="flex w-full justify-center">
        <Button
          key={`${teamMemberUser.id}-account-holder-chip`}
          label={teamMemberUser.inviteStatus === 'SENT' ? 'Resend Invite' : 'Send Invite'}
          icon="pi pi-send"
          onClick={confirmSendInvite}
          text
          type="button"
          className="account-holder-chip text-xs border-primary"
        />
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex w-full justify-center">
        <div className="flex flex-col mb-4">
          <Avatar image={avatar?.objectURL || actor?.avatarUrl} size="xlarge" shape="circle" className="w-52 h-52" />
          <div className="flex w-full justify-center">
            <FileUpload
              mode="basic"
              chooseOptions={{ icon: 'pi pi-pencil', iconOnly: true, className: '-mt-4 border-circle p-3 button-icon-only' }}
              onSelect={({ files }) => setUploadedAvatar(files[0])}
            />
          </div>
        </div>
      </div>
      { accountHolderChip() }
      <TextInput
        control={control}
        name="firstName"
        label="First Name"
      />
      <TextInput
        control={control}
        name="lastName"
        label="Last Name"
      />
      <EmailInput
        control={control}
        name="email"
        label="Email"
      />
      <PhoneNumberInput
        control={control}
        name="phoneNumber"
        label="Phone Number"
      />
      <InstagramHandleInput
        control={control}
        name="instagramHandle"
        label="Instagram Handle"
        required={false}
      />
      <MultiSelectInput
        control={control}
        searchable={false}
        name="roleIds"
        label="Roles"
        required={false}
        options={vendorRoles}
        showSelectAll={vendorRoles.length > 1}
        placeholder="Set roles"
      />
      { deactivateButton() }
      <div className="grid grid-cols-12 mt-6">
        <Button
          className="xxs:col-span-12 md:col-span-4 md:col-start-5"
          size="small"
          label={`${actor?.id ? 'Update' : 'Create'}`}
          type="submit"
        />
      </div>
    </form>
  );
}

export default CreateOrUpdateTeamMemberForm;
