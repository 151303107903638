import React, { useState, useEffect } from 'react';
import map from 'lodash/map';
import { classNames } from 'primereact/utils';

import TextAreaInput from 'components/Form/TextAreaInput';
import Attachments from 'components/RichTextView/Attachments';
import RichTextView from 'components/RichTextView';
import RichTextAreaMentionableInput, { DEFAULT_FORMATS } from 'components/Form/RichTextAreaMentionableInput';
import { mergeClassNames } from 'utils/styleUtils';

const EMPTY_BODY = '<p></p>';

function EditableTextArea({
  focus = true,
  name,
  updateFieldName = null,
  label = null,
  labelClassName = '',
  emptyTextViewClassName = '',
  required = true,
  attachments = [],
  updatable,
  isEditing,
  setIsEditing,
  control,
  setValue,
  getValues,
  formats = DEFAULT_FORMATS,
  attachmentsDisabled = true,
  mentionsDisabled = true,
  mentionables = [],
  placeholder = 'Add text...',
  textViewClassName = '',
  textViewWrapperClassName = '',
  setSubmitDisabled = () => {},
  onUpdate = () => {},
  onClear = () => {},
}) {
  const fieldName = name;
  const updateName = updateFieldName || fieldName;
  const text = getValues(fieldName);

  const [textAttachments, setTextAttachments] = useState(attachments || []);
  const [inputSubmitDisabled, setInputSubmitDisabled] = useState(false);
  const [editorClassName, setEditorClassName] = useState('w-full rounded-md');
  const [isFocused, setIsFocused] = useState(focus);

  useEffect(() => {
    setEditorClassName(
      isFocused
        ? `${editorClassName} focused-text-area border border-secondary`
        : 'w-full rounded-md',
    );
  }, [isFocused]);

  useEffect(() => setTextAttachments(attachments), [attachments]);

  useEffect(() => setSubmitDisabled(inputSubmitDisabled), [inputSubmitDisabled]);

  const hasBody = () => {
    let body = getValues(fieldName);
    if (body && typeof body === 'object') body = body[fieldName];
    return !!body && body.replace(/\s/g, '') !== EMPTY_BODY;
  };

  const checkIfSubmitShouldBeDisabled = () => {
    const shouldDisable = required && !hasBody() && !textAttachments.length;
    setInputSubmitDisabled(shouldDisable);
  };

  useEffect(() => {
    checkIfSubmitShouldBeDisabled();
    if (!attachmentsDisabled) {
      setValue('attachmentIds', map(textAttachments, 'id').sort());
    }
  }, [textAttachments, attachmentsDisabled]);

  const cancel = () => {
    setIsFocused(false);
    setValue(fieldName, text);
    setTextAttachments(attachments || []);
    setIsEditing(false);
    if (!text) onClear();
  };

  const setEditing = () => {
    if (updatable) setIsEditing(true);
  };

  const update = async () => {
    setIsFocused(false);

    const newAttachmentIds = map(textAttachments, 'id').sort();
    let newBody = null;

    if (hasBody()) {
      newBody = getValues(fieldName);
    } else if (newAttachmentIds.length) {
      newBody = '(Attached)';
    }

    if (required && !newBody) {
      return cancel();
    }

    const values = {
      [updateName]: newBody,
      ...(attachmentsDisabled ? {} : { attachmentIds: newAttachmentIds }),
    };

    setValue(fieldName, newBody);
    await onUpdate(values);

    setTextAttachments(attachments || []);
    return setIsEditing(false);
  };

  const onDeleteAttachment = (attachmentId) => setTextAttachments((prev) => prev.filter((attachment) => attachment.id !== attachmentId));

  if (isEditing) {
    return (
      <div className={`w-full ${textViewWrapperClassName}`}>
        {formats.length ? (
          <>
            <RichTextAreaMentionableInput
              focus={focus}
              control={control}
              name={fieldName}
              formats={formats}
              placeholder={placeholder}
              mentionsDisabled={mentionsDisabled}
              mentionables={mentionables}
              label={label}
              labelClassName={labelClassName}
              className="w-full p-0"
              editorClassName={editorClassName}
              onFocus={() => setIsFocused(true)}
              onBlur={inputSubmitDisabled ? cancel : update}
              onChange={checkIfSubmitShouldBeDisabled}
              onAttachmentsAdded={(newAttachments) => setTextAttachments([...textAttachments, ...newAttachments])}
              onImageUploadLoading={() => setInputSubmitDisabled(true)}
              onImageUploadComplete={() => setInputSubmitDisabled(false)}
            />
            <div className="w-full py-0 mb-3">
              <Attachments
                attachments={textAttachments}
                deleteable
                onDelete={onDeleteAttachment}
              />
            </div>
          </>
        ) : (
          <TextAreaInput
            required={required}
            focus={focus}
            control={control}
            name={fieldName}
            placeholder={placeholder}
            label={label}
            labelClassName={labelClassName}
            className="w-full p-0"
            onFocus={() => setIsFocused(true)}
            onChange={checkIfSubmitShouldBeDisabled}
          />
        )}
      </div>
    );
  }

  const viewClassNames = classNames(
    'w-full',
    { 'cursor-pointer': updatable },
    updatable ? textViewClassName : emptyTextViewClassName,
  );

  return (
    <div onClick={setEditing}>
      {label && <p className={`mb-2 ${labelClassName}`}>{label}</p>}
      {text || attachments.length ? (
        <RichTextView
          className={mergeClassNames(viewClassNames)}
          body={text}
          attachments={textAttachments}
        />
      ) : (
        <p className={viewClassNames}>{placeholder}</p>
      )}
    </div>
  );
}

export default EditableTextArea;
