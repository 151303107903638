import React, { useState, useEffect, useRef } from 'react';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import { DateTime } from 'luxon';
import map from 'lodash/map';
import compact from 'lodash/compact';

import { showErrorToast, showSuccessToast } from 'utils/toastUtils';
import AvatarWithName from 'components/AvatarWithName';
import RichTextView from 'components/RichTextView';
import { formatTimes } from 'utils/displayUtils';
import Table from 'components/Table';
import {
  EVENT_VENDORS_QUERY,
  EVENT_TEMPLATE_EVENT_VENDORS_QUERY,
  TIMELINE_TIME_BLOCK_CREATE_MUTATION,
  TIMELINE_TIME_BLOCK_UPDATE_MUTATION,
  TIMELINE_TIME_BLOCK_DELETE_MUTATION,
} from './graphql';
import CreateTimeBlockModal from './CreateTimeBlockForm/Modal';
import CreateSubTimeBlockGroupModal from './CreateSubTimeBlockGroupForm/Modal';
import SubTimeBlockGroupModal from './SubTimeBlockGroup/Modal';
import TimeBlockModal from './TimeBlock/Modal';

const SCREEN_SIZE_MAX_WIDTHS = {
  sm: 576,
  md: 990,
  lg: 1050,
};
const SCREEN_WIDTH_COLUMNS = {
  sm: ['time', 'description', 'hosts', 'showSubBlocks'],
  md: ['time', 'description', 'hosts', 'showSubBlocks'],
  lg: ['time', 'description', 'hosts', 'details', 'showSubBlocks'],
};
const COLUMN_CLASS_NAMES_BY_SCREEN_SIZE = {
  sm: {
    time: 'w-1/6',
    description: 'w-3/6',
    hosts: 'w-1/6',
    showSubBlocks: 'w-1/6',
  },
  md: {
    time: 'w-1/6',
    description: 'w-4/6',
    hosts: 'w-1/6',
    showSubBlocks: 'w-1/6',
  },
  lg: {
    time: 'w-1/12',
    description: 'w-4/12',
    hosts: 'w-2/12',
    details: 'w-4/12',
    showSubBlocks: 'w-1/12',
  },
};
const SUB_TIME_BLOCK_GROUP_COLUMNS = ['time', 'description', 'hosts'];
const FULL_TABLE_MIN_WIDTH = SCREEN_SIZE_MAX_WIDTHS.sm;
export default function Timeline(props) {
  const {
    className: timelineClassName = '',
    task = null,
    timeline,
    timeBlocks,
    isEventTemplate,
    loading = false,
    showCreate = false,
    expandable = true,
    isSubTimeBlockGroup = false,
    subTimeBlockGroupId = null,
    refetch = () => {},
    onCreate = () => {},
  } = props;

  const { eventId } = useParams();
  const toastRef = useRef();
  const [expandedRows, setExpandedRows] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const screenSizeByWidth = () => {
    if (window.innerWidth < 990) {
      return 'sm';
    } if (window.innerWidth < 1050) {
      return 'md';
    }
    return 'lg';
  };

  const [screenSize, setScreenSize] = useState(screenSizeByWidth());

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenSize(screenSizeByWidth());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [activeTimeBlockId, setActiveTimeBlockId] = useState(null);
  const [activeTimeBlock, setActiveTimeBlock] = useState(null);
  const [activeSubTimeBlockGroupId, setActiveSubTimeBlockGroupId] = useState(null);
  const [activeSubTimeBlockGroup, setActiveSubTimeBlockGroup] = useState(null);
  const [activeParentTimeBlock, setActiveParentTimeBlock] = useState(null);
  const [activeParentTimeBlockId, setActiveParentTimeBlockId] = useState(null);
  const [eventVendors, setEventVendors] = useState([]);
  const [currentVendor, setCurrentVendor] = useState(null);
  const [eventHostActors, setEventHostActors] = useState([]);
  const [mappedActors, setMappedActors] = useState([]);
  const [mappedVendors, setMappedVendors] = useState([]);
  const [mappedVenues, setMappedVenues] = useState([]);
  const [mappedSubTimeBlockGroupVendors, setMappedSubTimeBlockGroupVendors] = useState([]);
  const [mappedSubTimeBlockGroupVenues, setMappedSubTimeBlockGroupVenues] = useState([]);
  const [showCreateTimeBlockModal, setShowCreateTimeBlockModal] = useState(timeBlocks.length === 1 && !timeBlocks[0].id);
  const [showCreateSubTimeBlockGroupModal, setShowCreateSubTimeBlockGroupModal] = useState(false);
  const [showSubTimeBlockGroupModal, setShowSubTimeBlockGroupModal] = useState(false);
  const [showTimeBlockModal, setShowTimeBlockModal] = useState(false);

  const mapActorOrVendor = ({ option = {}, eventVendorId = null }) => ({
    id: option.id,
    uuid: option.uuid,
    value: option.id,
    name: option.name,
    initials: option.initials,
    avatarUrl: option.avatarUrl,
    label: option.name,
    eventVendorId,
  });

  const mapActorOptions = () => eventHostActors.map(({ hostActor }) => mapActorOrVendor({ option: hostActor }));
  const mapVendorOptions = ({ vendors }) => compact(vendors.map((vendor) => (!vendor.isVenue ? mapActorOrVendor({ option: vendor }) : null)));
  const mapVenueOptions = ({ vendors }) => compact(vendors.map((vendor) => (vendor.isVenue ? mapActorOrVendor({ option: vendor }) : null)));

  useEffect(() => {
    if (activeTimeBlock || showCreateTimeBlockModal || showCreateSubTimeBlockGroupModal || showSubTimeBlockGroupModal) {
      setMappedActors(mapActorOptions());

      const vendors = map(eventVendors, 'vendor');

      setMappedVendors(mapVendorOptions({ vendors }));
      setMappedVenues(mapVenueOptions({ vendors }));
    }
  }, [activeTimeBlock, showCreateTimeBlockModal, showCreateSubTimeBlockGroupModal, showSubTimeBlockGroupModal, eventVendors]);

  const buildActiveTimeBlock = () => {
    if (!activeTimeBlockId) { return null; }

    return timeline.timeBlocks.find((tb) => tb.id === activeTimeBlockId);
  };

  useEffect(() => {
    if (activeParentTimeBlockId) {
      const parentTimeBlock = timeline.timeBlocks.find((tb) => tb.id === activeParentTimeBlockId);
      const { vendors } = parentTimeBlock;
      setMappedSubTimeBlockGroupVendors(mapVendorOptions({ vendors }));
      setMappedSubTimeBlockGroupVenues(mapVenueOptions({ vendors }));
      setActiveParentTimeBlock(parentTimeBlock);
    } else {
      setActiveParentTimeBlock(null);
    }
  }, [activeParentTimeBlockId]);

  useEffect(() => {
    if (activeTimeBlockId) {
      setActiveTimeBlock(buildActiveTimeBlock());
      setShowTimeBlockModal(true);
    } else {
      setActiveTimeBlock(null);
    }
  }, [activeTimeBlockId]);

  useEffect(() => {
    if (activeSubTimeBlockGroupId) {
      let subTimeBlockGroup;
      const parentTimeBlock = timeline.timeBlocks.find((timeBlock) => (
        timeBlock.subTimeBlocks.find((subTimeBlock) => {
          if (subTimeBlock.id === activeSubTimeBlockGroupId) {
            subTimeBlockGroup = subTimeBlock;
            return true;
          }

          return false;
        })
      ));
      const { vendors } = parentTimeBlock;
      setMappedSubTimeBlockGroupVendors(mapVendorOptions({ vendors }));
      setMappedSubTimeBlockGroupVenues(mapVenueOptions({ vendors }));
      setActiveParentTimeBlock(parentTimeBlock);
      setActiveSubTimeBlockGroup(subTimeBlockGroup);
      setShowSubTimeBlockGroupModal(true);
    } else {
      setActiveSubTimeBlockGroup(null);
      setShowSubTimeBlockGroupModal(false);
    }
  }, [activeSubTimeBlockGroupId]);

  const closeModals = () => {
    setActiveTimeBlockId(null);
    setActiveSubTimeBlockGroupId(null);
    setShowTimeBlockModal(false);
    setShowSubTimeBlockGroupModal(false);
    setShowCreateSubTimeBlockGroupModal(false);
    setShowCreateTimeBlockModal(false);
  };

  const eventVendorsQuery = isEventTemplate ? EVENT_TEMPLATE_EVENT_VENDORS_QUERY : EVENT_VENDORS_QUERY;
  useQuery(
    eventVendorsQuery,
    {
      variables: {
        eventFilters: { id: eventId },
        eventHostActorFilters: { status: ['ADDED', 'APPROVED', 'INVITED'] },
      },
      onCompleted: (data) => {
        const eventData = isEventTemplate ? data.currentActor.eventTemplates[0] : data.currentActor.events.edges[0].node;

        setCurrentVendor(data.currentVendor);
        setEventVendors(eventData.eventVendors || []);
        setEventHostActors(eventData.eventHostActors || []);
      },
      onError: (mutationError) => {
        const { graphQLErrors, message } = mutationError;

        if (message) {
          showErrorToast(toastRef, mutationError.message);
        } else {
          graphQLErrors.map(({ message: gqlError }) => (
            showErrorToast(toastRef, gqlError)
          ));
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  );

  const resetModals = () => {
    setActiveTimeBlockId(null);
    setShowTimeBlockModal(false);
    setShowSubTimeBlockGroupModal(false);
    setActiveParentTimeBlockId(null);
    setActiveParentTimeBlock(null);
    setActiveSubTimeBlockGroup(null);
  };

  const [timeBlockCreateMutation] = useMutation(TIMELINE_TIME_BLOCK_CREATE_MUTATION, {
    onCompleted: ({ timelineTimeBlockCreate }) => {
      onCreate();
      resetModals();
      refetch();
      showSuccessToast(toastRef, 'Created time block!');

      if (activeTimeBlock) {
        setActiveTimeBlock({
          ...activeTimeBlock,
          events: activeTimeBlock.events.concat([{ ...timelineTimeBlockCreate.timeBlock, isUpdatableByCurrentActor: true }]),
        });
      }
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ));
    },
  });

  const [timeBlockDeleteMutation] = useMutation(TIMELINE_TIME_BLOCK_DELETE_MUTATION, {
    onCompleted: async () => {
      resetModals();
      await refetch();
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ));
    },
  });

  const deleteTimeBlock = ({ timeBlockId }) => timeBlockDeleteMutation({ variables: { input: { id: timeBlockId } } });
  const createTimeBlock = ({
    startStr,
    endStr = null,
    attachmentIds,
    participantActorIds,
    vendorIds,
    description,
    details,
    subTimeBlocks = [],
  }) => {
    if (loading) { return; }

    timeBlockCreateMutation({
      variables: {
        input: {
          vendorIds,
          timelineId: timeline.id,
          startDateTime: startStr,
          description,
          details,
          taskId: task?.id,
          attachmentIds,
          participantActorIds,
          parentTimeBlockId: activeParentTimeBlockId,
          subTimeBlocks,
          endDateTime: endStr,
        },
      },
    });
  };

  const [timeBlockUpdateMutation] = useMutation(TIMELINE_TIME_BLOCK_UPDATE_MUTATION, {
    onCompleted: () => {
      resetModals();
      refetch();
      showSuccessToast(toastRef, 'Updated time block!');
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => showErrorToast(toastRef, message));
    },
  });

  const updateTimeBlock = async ({
    timeBlockId = activeTimeBlockId,
    description,
    startStr,
    endStr,
    details,
    attachmentIds,
    participantActorIds,
    vendorIds,
    subTimeBlocks = [],
  }) => {
    if (loading) { return; }

    const variables = {
      id: timeBlockId,
      description,
      details,
      attachmentIds,
      participantActorIds,
      vendorIds,
      startDateTime: startStr ? DateTime.fromISO(startStr) : null,
      endDateTime: endStr ? DateTime.fromISO(endStr) : null,
    };

    const subTimeBlocksInput = subTimeBlocks.length ? { subTimeBlocks } : {};

    const response = await timeBlockUpdateMutation({
      variables: {
        input: {
          ...variables,
          ...subTimeBlocksInput,
        },
      },
    });

    if (!activeTimeBlock) { return; }

    setActiveTimeBlock({ ...activeTimeBlock, ...response.data.timelineTimeBlockUpdate.timeBlock, isUpdatableByCurrentActor: true });
  };

  const onClickCreateSubTimeBlock = ({ parentTimeBlockId }) => {
    setShowCreateSubTimeBlockGroupModal(true);
    setActiveParentTimeBlockId(parentTimeBlockId);
    setActiveTimeBlockId(null);
  };

  const teamMemberOptionsForVendorId = ({ vendorId }) => {
    if (!vendorId) { return []; }

    const eventVendor = eventVendors.find((ev) => ev.vendor.id === vendorId);

    return eventVendor.teamMemberActors.map((actor) => mapActorOrVendor({ option: actor }));
  };

  const mapVenues = ({ vendors }) => vendors.filter((eventVendor) => eventVendor.isVenue);
  const venuesInfo = ({ venues }) => (
    venues.map((venue) => (
      <div className="flex items-center text-xs"><i className="pi pi-map-marker mr-1 text-xs" />{ venue.name }</div>
    ))
  );

  const actorsOrVendorsInfo = ({ id, actorsOrVendors }) => {
    const showText = (actorsOrVendors.length < 3 && screenSize === 'lg') || screenWidth < FULL_TABLE_MIN_WIDTH;

    return (
      <div className={`${showText ? '' : 'flex items-center'}`}>
        {
          actorsOrVendors.map((actorOrVendor, idx) => (
            <div className={showText ? 'mb-1' : ''}>
              <AvatarWithName
                key={`${id + idx}-${actorOrVendor.id}`}
                avatarSize="small"
                avatarClassName="text-xs w-6 h-6 mr-1"
                id={id + actorOrVendor.id + idx}
                initials={actorOrVendor.initials}
                avatarUrl={actorOrVendor.avatarUrl}
                tooltip={showText ? null : actorOrVendor.name}
                text={showText ? actorOrVendor.name : null}
              />
            </div>
          ))
        }
      </div>
    );
  };

  const detailsInfo = ({ details, attachments, className = '' }) => (
    <RichTextView
      className={className}
      body={details}
      attachments={attachments}
    />
  );

  const timeAndVenueInfo = ({ startTime, endTime, vendors }) => (
    <>
      <p className="font-semibold">{ formatTimes({ startTime, endTime }) }</p>
      <p className="mt-1">{ venuesInfo({ venues: mapVenues({ vendors }) }) }</p>
    </>
  );

  const mapNonVenues = ({ vendors }) => vendors.filter((eventVendor) => !eventVendor.isVenue);

  const rowExpansionTemplate = ({ id, subTimeBlocks }) => (
    <div>
      <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
        {
          subTimeBlocks.map((subTimeBlock) => (
            <div className="text-sm">
              <p className="text-with-line-breaks mt-2 font-semibold">{ subTimeBlock.description }</p>
              <p className="text-xs mt-1 mb-2">{ timeAndVenueInfo(subTimeBlock) }</p>
              <div className="text-sm">
                { actorsOrVendorsInfo({ id: subTimeBlock.id, actorsOrVendors: mapNonVenues({ vendors: subTimeBlock.vendors }) }) }
              </div>
              <div className="text-xs">{ actorsOrVendorsInfo({ id: subTimeBlock.id, actorsOrVendors: subTimeBlock.teamMemberActors }) }</div>
              {
                  subTimeBlock.details ? (
                    <>
                      <p className="text-xs font-semibold mt-3">Details</p>
                      <div className="">{ detailsInfo({ details: subTimeBlock.details, className: 'text-xs' }) }</div>
                    </>
                  ) : null
                }
              <Timeline
                {...props}
                isEventTemplate={isEventTemplate}
                className="text-sm mt-4 mb-4"
                timeBlocks={subTimeBlock.subTimeBlocks}
                expandable={false}
                showCreate={false}
                isSubTimeBlockGroup
                subTimeBlockGroupId={subTimeBlock.id}
              />
            </div>
          ))
        }
      </div>
      <Button
        label="Add Sub Time Block Group"
        className="mt-2 text-xs"
        type="button"
        size="small"
        onClick={() => onClickCreateSubTimeBlock({ parentTimeBlockId: id })}
      />
    </div>
  );

  const hasSubTimeBlocks = ({ subTimeBlocks }) => subTimeBlocks.length;

  const onRowClick = ({ data }) => {
    if (expandable) {
      setActiveTimeBlockId(data.id);
    } else {
      setActiveSubTimeBlockGroupId(subTimeBlockGroupId);
    }
  };

  const isColumnVisibleForScreenSize = ({ columnName }) => SCREEN_WIDTH_COLUMNS[screenSize].includes(columnName);

  const columnVisibilityClassName = ({ columnName }) => {
    if ((isSubTimeBlockGroup && !SUB_TIME_BLOCK_GROUP_COLUMNS.includes(columnName)) || !isColumnVisibleForScreenSize({ columnName })) {
      return 'hidden';
    }

    return '';
  };

  const columnClassName = ({ columnName }) => {
    let columnClass = columnVisibilityClassName({ columnName });

    columnClass += ` ${COLUMN_CLASS_NAMES_BY_SCREEN_SIZE[screenSize][columnName]}`;

    return columnClass;
  };

  const stackedParticipantsInfo = ({ id, vendors, hostActors }) => (
    <div>
      <div>
        {
          mapNonVenues({ vendors }).length ? (
            <>
              <p className="font-semibold text-xs mb-1">Vendors</p>
              { actorsOrVendorsInfo({ id, actorsOrVendors: mapNonVenues({ vendors }) })}
            </>
          )
            : null
        }
      </div>
      <div>
        {
          hostActors.length ? (
            <>
              <p className="font-semibold text-xs mb-1 mt-6">Hosts</p>
              { actorsOrVendorsInfo({ id, actorsOrVendors: hostActors })}
            </>
          )
            : null
        }
      </div>
    </div>
  );

  const participantsColumn = () => {
    if (isSubTimeBlockGroup) {
      return (
        <Column
          header="Participants"
          className={columnClassName({ columnName: 'hosts' })}
          body={({ id, hostActors }) => actorsOrVendorsInfo({ id, actorsOrVendors: hostActors })}
        />
      );
    }
    return (
      <Column
        header="Participants"
        className={columnClassName({ columnName: 'hosts' })}
        body={stackedParticipantsInfo}
      />
    );
  };

  const descriptionInfo = ({ description, details, attachments }) => {
    if (isColumnVisibleForScreenSize({ columnName: 'details' })) {
      return <p className="text-with-line-breaks">{ description }</p>;
    }
    return (
      <div>
        <p className="text-with-line-breaks">{ description }</p>
        <p className="mt-2 text-xs font-semibold">Details</p>
        { detailsInfo({ details, attachments }) }
      </div>
    );
  };

  const smallScreenTimeline = (data) => {
    const mapNonVenueVendors = mapNonVenues({ vendors: data.vendors });

    const {
      id, vendors, hostActors,
    } = data;

    return (
      <>
        <p className="font-semibold">{formatTimes(data)}</p>
        <p className="text-xs">{ venuesInfo({ venues: mapVenues({ vendors }) }) }</p>
        <p className="my-6">{descriptionInfo(data)}</p>
        {
          isSubTimeBlockGroup ? (
            actorsOrVendorsInfo({ id, actorsOrVendors: hostActors })
          ) : (
            <div>
              <div>
                {
                  mapNonVenueVendors.length ? (
                    <>
                      <p className="font-semibold text-xs mb-1">Vendors</p>
                      { actorsOrVendorsInfo({ id, actorsOrVendors: mapNonVenueVendors }) }
                    </>
                  )
                    : null
                }
              </div>
              <div>
                {
                  hostActors.length ? (
                    <>
                      <p className="font-semibold text-xs mb-1 mt-6">Hosts</p>
                      { actorsOrVendorsInfo({ id, actorsOrVendors: hostActors })}
                    </>
                  )
                    : null
                }
              </div>
            </div>
          )
        }
      </>
    );
  };

  const timelineTable = () => {
    if (!timeBlocks.length) { return 'Add some time blocks and get this party started!'; }

    if (screenWidth >= FULL_TABLE_MIN_WIDTH) {
      return (
        <Table
          data={timeBlocks}
          clickable
          onRowClick={onRowClick}
          reorderableRows
          selectionMode="checkbox"
          className={`w-full text-sm border rounded ${timelineClassName}`}
          expandableRowGroups
          expandedRows={expandedRows}
          onRowToggle={({ data }) => (expandable ? setExpandedRows(data) : {})}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column
            headerClassName="sticky"
            header="Time"
            className={`text-nowrap ${columnClassName({ columnName: 'time' })}`}
            body={timeAndVenueInfo}
          />
          <Column
            header="Description"
            field="description"
            className={columnClassName({ columnName: 'description' })}
            body={descriptionInfo}
          />
          { participantsColumn() }
          <Column
            className={columnClassName({ columnName: 'details' })}
            headerClassName={columnVisibilityClassName({ columnName: 'details' })}
            header="Details"
            field="details"
            body={detailsInfo}
          />
          {
            expandable && (
              <Column
                header="Sub Time Blocks"
                headerClassName={columnVisibilityClassName({ columnName: 'showSubBlocks' })}
                className={columnClassName({ columnName: 'showSubBlocks' })}
                expander={hasSubTimeBlocks}
              />
            )
          }
        </Table>
      );
    }
    return (
      <Table
        data={timeBlocks}
        clickable
        showHeaders={false}
        onRowClick={onRowClick}
        reorderableRows
        selectionMode="checkbox"
        className={`font-normal border rounded ${timelineClassName} w-full`}
        expandableRowGroups
        expandedRows={expandedRows}
        onRowToggle={({ data }) => (expandable ? setExpandedRows(data) : {})}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column body={smallScreenTimeline} />
        { expandable && <Column expander={hasSubTimeBlocks} /> }
      </Table>
    );
  };

  const onClickCreateTimeBlock = () => {
    setActiveTimeBlockId(null);
    setShowCreateTimeBlockModal(true);
  };

  return (
    <>
      {
        showCreate ? (
          <div className="w-full flex justify-end mb-4 mb-4">
            <Button
              rounded
              className="h-8 w-8"
              icon="pi pi-plus"
              disabled={loading}
              onClick={onClickCreateTimeBlock}
            />
          </div>
        ) : null
      }
      { timelineTable() }
      {
        showCreateTimeBlockModal && (
          <CreateTimeBlockModal
            actorOptions={mappedActors}
            vendorOptions={mappedVendors}
            venueOptions={mappedVenues}
            createTimeBlock={createTimeBlock}
            currentVendor={currentVendor}
            eventVendors={eventVendors}
            timeline={timeline}
            task={task}
            minimumStartTime={timeline.startTimeu}
            toastRef={toastRef}
            show={showCreateTimeBlockModal}
            onHide={closeModals}
          />
        )
      }
      {
        showTimeBlockModal && activeTimeBlock && (
          <TimeBlockModal
            updateTimeBlock={updateTimeBlock}
            deleteTimeBlock={deleteTimeBlock}
            currentVendor={currentVendor}
            actorOptions={mappedActors}
            vendorOptions={mappedVendors}
            venueOptions={mappedVenues}
            timeline={timeline}
            timeBlock={activeTimeBlock}
            show={showTimeBlockModal}
            onClickCreateSubTimeBlock={onClickCreateSubTimeBlock}
            onHide={closeModals}
          />
        )
      }
      {
        showCreateSubTimeBlockGroupModal && activeParentTimeBlock && (
          <CreateSubTimeBlockGroupModal
            createTimeBlock={createTimeBlock}
            actorOptions={mappedActors}
            vendorOptions={mappedSubTimeBlockGroupVendors}
            venueOptions={mappedSubTimeBlockGroupVenues}
            teamMemberOptionsForVendorId={teamMemberOptionsForVendorId}
            parentTimeBlock={activeParentTimeBlock}
            currentVendor={currentVendor}
            timeline={timeline}
            toastRef={toastRef}
            show={showCreateSubTimeBlockGroupModal}
            onHide={closeModals}
          />
        )
      }
      {
        showSubTimeBlockGroupModal && activeSubTimeBlockGroup && (
          <SubTimeBlockGroupModal
            updateTimeBlock={updateTimeBlock}
            deleteTimeBlock={deleteTimeBlock}
            setShowSubTimeBlockGroupModal={setShowSubTimeBlockGroupModal}
            actorOptions={mappedActors}
            vendorOptions={mappedSubTimeBlockGroupVendors}
            venueOptions={mappedSubTimeBlockGroupVenues}
            teamMemberOptionsForVendorId={teamMemberOptionsForVendorId}
            parentTimeBlock={activeParentTimeBlock}
            subTimeBlockGroup={activeSubTimeBlockGroup}
            currentVendor={currentVendor}
            toastRef={toastRef}
            show={showSubTimeBlockGroupModal}
            onHide={closeModals}
          />
        )
      }
      <Toast ref={toastRef} position="bottom-left" />
    </>
  );
}
