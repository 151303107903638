import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { useInView } from 'react-intersection-observer';
import { useMutation } from '@apollo/react-hooks';
import { Card } from 'primereact/card';

import EditableTextArea from 'components/Form/EditableFields/TextArea';
import EditableText from 'components/Form/EditableFields/Text';
import Comments from 'containers/Tasks/Index/CompleteTaskForm/Comments';
import { showErrorToast } from 'utils/toastUtils';
import {
  MENTION_MARK_AS_READ_MUTATION,
  SUBTASK_QUESTION_CREATE_MUTATION,
  SUBTASK_QUESTION_UPDATE_MUTATION,
} from './graphql';

function CompleteQuestionForm({
  task,
  subtaskId,
  isSubtaskTemplate,
  templateName,
  setSubtaskUpdated,
  onCreate = () => {},
  header,
  mentionables,
  currentActor = {},
  question,
  onRemoveEmptyQuestion,
  isUpdatableByCurrentActor,
  refetch,
}) {
  const [isEditingTemplateName, setIsEditingTemplateName] = useState(isSubtaskTemplate && !question.id);
  const [isEditingQuestion, setIsEditingQuestion] = useState(!subtaskId);
  const {
    id: questionId, body = '', answers = [], attachments = [], mentions = [],
  } = question;

  const { control, setValue, getValues } = useForm({
    defaultValues: { body, templateName },
  });

  const toastRef = useRef();
  const { ref, inView } = useInView();

  const currentActorUnreadMentions = mentions.filter(
    (mention) => mention.mentionedActor.id === currentActor.id && !mention.read,
  );

  const [updateQuestionMutation] = useMutation(SUBTASK_QUESTION_UPDATE_MUTATION, {
    onCompleted: refetch,
    onError: ({ graphQLErrors }) => {
      graphQLErrors.forEach(({ message }) => showErrorToast(toastRef, message));
    },
  });

  const [createQuestionMutation] = useMutation(SUBTASK_QUESTION_CREATE_MUTATION, {
    onCompleted: async ({ subtaskQuestionCreate }) => {
      onCreate(subtaskQuestionCreate.question);
      await refetch();
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.forEach(({ message }) => showErrorToast(toastRef, message));
    },
  });

  const [markMentionAsRead] = useMutation(MENTION_MARK_AS_READ_MUTATION, {
    onCompleted: refetch,
    onError: ({ graphQLErrors, message }) => {
      if (message) {
        showErrorToast(toastRef, message);
      } else {
        graphQLErrors.forEach(({ message: gqlError }) => showErrorToast(toastRef, `Error updating task: ${gqlError}`));
      }
    },
  });

  useEffect(() => {
    setValue('templateName', templateName);
  }, [templateName, setValue]);

  useEffect(() => {
    if (!task.isTemplate && inView && currentActorUnreadMentions.length) {
      currentActorUnreadMentions.forEach(({ id }) => markMentionAsRead({ variables: { input: { id } } }));
    }
  }, [inView, task.isTemplate, currentActorUnreadMentions, markMentionAsRead]);

  const updateQuestion = (values) => updateQuestionMutation({
    variables: {
      input: {
        id: questionId,
        templateName: getValues('templateName'),
        ...values,
      },
    },
  });

  const createQuestion = () => {
    const template = getValues('templateName');
    const bodyValue = getValues('body');
    if (isSubtaskTemplate && (!template || !bodyValue)) return;

    createQuestionMutation({
      variables: {
        input: {
          taskId: task.id,
          isTemplate: isSubtaskTemplate,
          templateName: template,
          body: bodyValue,
          attachmentIds: getValues('attachmentIds'),
        },
      },
    });
  };

  const onClearQuestion = () => {
    setIsEditingQuestion(false);
    onRemoveEmptyQuestion();
  };

  const cardClassNames = classNames(
    'text-base',
    'relative',
    'flex',
    'justify-center',
    'subtask-card',
    { 'subtask-question-card-with-answers': subtaskId },
  );

  return (
    <div ref={ref}>
      <Card
        title={header}
        key={`${subtaskId}-subtask-card`}
        className={cardClassNames}
      >
        {isSubtaskTemplate && (
          <EditableText
            focus
            className={`w-1/2 ${questionId && !isEditingTemplateName ? 'mb-5' : ''}`}
            textInputClassName="w-1/2 mb-4"
            text={getValues('templateName')}
            label="Template Name"
            labelClassName="font-semibold"
            isEditing={isEditingTemplateName || !questionId}
            setIsEditing={setIsEditingTemplateName}
            name="templateName"
            updatable={isUpdatableByCurrentActor}
            onUpdate={questionId ? updateQuestion : createQuestion}
            control={control}
            setValue={setValue}
            getValues={getValues}
          />
        )}
        <EditableTextArea
          focus={!isEditingTemplateName}
          name="body"
          labelClassName="font-semibold"
          attachmentsDisabled={false}
          isEditing={isEditingQuestion || (isSubtaskTemplate && !questionId)}
          setIsEditing={setIsEditingQuestion}
          mentionsDisabled={false}
          mentionables={mentionables}
          attachments={attachments}
          updatable={isUpdatableByCurrentActor}
          onUpdate={questionId ? updateQuestion : createQuestion}
          onClear={onClearQuestion}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
      </Card>
      {questionId && !task.isTemplate && !isSubtaskTemplate && (
        <Comments
          mentionables={mentionables}
          onCreate={() => setSubtaskUpdated(true)}
          comments={answers}
          focus={!answers.length}
          commentableId={questionId}
          commentType="answer"
          currentActor={currentActor}
          refetch={refetch}
        />
      )}
    </div>
  );
}

export default CompleteQuestionForm;
