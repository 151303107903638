import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useNavigate, useLocation, useParams } from 'react-router';

import { showErrorToast } from 'utils/toastUtils';
import CompleteTaskModal from '../CompleteTaskForm/Modal';
import TaskBoard from '../Board';
import { TASK_QUERY, TASK_TEMPLATE_QUERY } from '../graphql';

function TasksIndexView({
  toastRef,
  actors,
  actorOptions,
  tasks,
  refetchTasks,
  eventId,
  fetchEventVendors,
  eventVendors,
  subtaskTemplates,
  currentActor,
  currentVendor,
  showEventInfo,
  labels,
  selectedGrouping,
  showEmptyGroups,
  showCompleted,
}) {
  const { taskId } = useParams();
  const [activeTask, setActiveTask] = useState(null);
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const isTemplate = path.includes('tasks/templates');
  const isEventTemplate = path.includes('events/templates');
  const [
    fetchActiveTemplate, { refetch: refetchActiveTemplate },
  ] = useLazyQuery(
    TASK_TEMPLATE_QUERY,
    {
      onCompleted: (data) => {
        setActiveTask(data.currentActor?.taskTemplates[0]);
      },
      onError: ({ graphQLErrors }) => (
        graphQLErrors.map(({ message }) => (
          showErrorToast(toastRef, message)
        ))
      ),
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const [
    fetchActiveTask, { refetch: refetchActiveTask },
  ] = useLazyQuery(
    TASK_QUERY,
    {
      onCompleted: (data) => {
        const task = data.currentActor.tasks.edges[0].node;

        setActiveTask(task);
        if (task.event?.id !== eventId) { fetchEventVendors({ variables: { eventFilters: { id: task.event?.id } } }); }
      },
      onError: ({ graphQLErrors }) => (
        graphQLErrors.map(({ message }) => (
          showErrorToast(toastRef, message)
        ))
      ),
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );
  const fetchQuery = (variables) => (isTemplate ? fetchActiveTemplate(variables) : fetchActiveTask(variables));

  useEffect(() => {
    if (taskId) {
      fetchQuery(({ variables: { taskFilters: { id: taskId } } }));
    } else {
      setActiveTask(null);
    }
  }, [taskId]);

  const refetchQuery = () => (isTemplate ? refetchActiveTemplate() : refetchActiveTask());

  const templatesPath = isEventTemplate ? 'templates/' : '';
  const closeCompleteTaskModal = async () => {
    setActiveTask(null);

    if (eventId) {
      navigate(`/app/events/${templatesPath}${eventId}/tasks`);
    } else {
      navigate('/app/tasks');
    }
  };

  const onTaskClick = (task) => {
    if (eventId) {
      return navigate(`/app/events/${templatesPath}${eventId}/tasks/${task.id}`);
    }

    return navigate(`/app/tasks/${task.id}`);
  };

  return (
    <>
      <TaskBoard
        tasks={tasks}
        refetchTasks={refetchTasks}
        actors={actors}
        labels={labels}
        currentActor={currentActor}
        currentVendorId={currentVendor?.id}
        actorOptions={actorOptions}
        showEventInfo={showEventInfo}
        onCardClick={onTaskClick}
        selectedGrouping={selectedGrouping}
        showEmptyGroups={showEmptyGroups}
        showCompleted={showCompleted}
      />
      {
        activeTask && (
          <CompleteTaskModal
            labels={labels}
            currentVendorName={currentVendor?.name}
            subtaskTemplates={subtaskTemplates}
            eventVendors={eventVendors}
            refetch={refetchQuery}
            show={activeTask}
            currentActor={currentActor}
            task={activeTask}
            actorOptions={actorOptions}
            onHide={closeCompleteTaskModal}
            toastRef={toastRef}
          />
        )
      }
    </>
  );
}

export default TasksIndexView;
