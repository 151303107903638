import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Table from 'components/Table';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import AvatarWithName from 'components/AvatarWithName';
import CreateEventModal from '../CreateEventModal';

function ManageTemplatesForm({
  eventTemplates, refetchTemplates,
}) {
  const navigate = useNavigate();
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);

  const actorColumn = (actor) => (
    <AvatarWithName
      id={actor.id}
      initials={actor.initials}
      avatarUrl={actor.avatarUrl}
      text={actor.name}
    />
  );
  const openTemplateModal = ({ id }) => {
    navigate(`/app/events/templates/${id}`);
  };

  return (
    <>
      <div className="flex w-full justify-end">
        <Button
          rounded
          className="h-8 w-8"
          icon="pi pi-plus"
          onClick={() => setShowCreateEventModal(true)}
        />
      </div>
      <Table
        data={eventTemplates}
        pageSize={10}
        clickable
        onRowClick={({ data }) => openTemplateModal({ id: data.id })}
      >
        <Column
          field="templateName"
          header="Name"
        />
        <Column
          field="createdByActor"
          header="Created By"
          className="xxs:hidden lg:table-cell"
          headerClassName="xxs:hidden lg:table-cell"
          body={({ createdByActor }) => actorColumn(createdByActor)}
        />
        <Column
          field="lastUpdatedByActor"
          header="Last Updated By"
          className="xxs:hidden lg:table-cell"
          headerClassName="xxs:hidden lg:table-cell"
          body={({ lastUpdatedByActor }) => actorColumn(lastUpdatedByActor)}
        />
      </Table>
      <CreateEventModal
        isTemplate
        refetchEvents={refetchTemplates}
        show={showCreateEventModal}
        onHide={() => setShowCreateEventModal(false)}
      />
    </>
  );
}

export default ManageTemplatesForm;
