import React, { useRef, useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { Editor } from 'primereact/editor';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import without from 'lodash/without';
import Quill from 'quill';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
/* eslint-disable import/no-unresolved */
import 'quill-mention/autoregister';
/* eslint-enable import/no-unresolved */

import { renderFormFieldError } from 'utils/formUtils';
import Toast from 'components/Toast';
import { titleize } from 'utils/stringUtils';
import { mergeClassNames } from 'utils/styleUtils';
import LoadingImagePlaceholder from './LoadingImagePlaceholder';
import Image from './Image';

import { UPLOAD_FILES_MUTATION } from './graphql';

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);

export const DEFAULT_FORMATS = ['bold', 'italic', 'underline', 'strike', 'list', 'link', 'image', 'attachment', 'mention', 'loadingImagePlaceholder'];
const IGNORE_IN_TOOLBAR_FORMATS = ['attachment', 'mention', 'loadingImagePlaceholder'];
Quill.register({ 'formats/loadingImagePlaceholder': LoadingImagePlaceholder }, true);
Quill.register({ 'formats/image': Image }, true);
Quill.register({ 'formats/attachment': Image }, true);
function RichTextAreaMentionableInput(props) {
  const {
    control,
    focus = false,
    formats = DEFAULT_FORMATS,
    editorClassName = '',
    mentionables,
    mentionsDisabled = false,
    placeholder = "Mention people using '@'",
    disabledMessage = 'Mentions are disabled',
    name,
    label,
    required = true,
    className,
    labelClassName = '',
    controllerProps,
    inputProps,
    onFocus = () => {},
    onBlur = () => {},
    onImageUploadLoading = () => {},
    onImageUploadComplete = () => {},
    onAttachmentsAdded = () => {},
    onChange = () => {},
  } = props;
  const editorRef = useRef(null);
  const [toastDetails, setToastDetails] = useState(null);
  const [quill, setQuill] = useState(null);
  const [range, setRange] = useState({ index: 0, length: 0 });
  const [uploadingImage, setUploadingImage] = useState(false);

  const {
    field: { value, onChange: onFieldChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: null,
    ...controllerProps,
  });

  useEffect(() => {
    window.Quill = Quill;
  }, []);

  useEffect(() => {
    if (!quill) { return; }

    if (focus) {
      quill.setSelection(quill.getLength(), 0);
      quill.focus();
    }

    quill.keyboard.addBinding({
      key: 'S',
      shortKey: true,
    }, (currentRange, context) => {
      if (context.format.strike) {
        return quill.format('strike', false);
      }
      return quill.format('strike', true);
    });
  }, [quill]);

  const deleteSelectedText = () => {
    const textIsSelected = range.length > 0;

    if (textIsSelected) {
      quill.deleteText(range.index, range.length);
    }
  };

  const insertImageToEditor = ({ url }) => {
    deleteSelectedText();

    const { index } = range;

    quill.insertEmbed(index + 1, 'image', url);
  };

  const [uploadAttachmentsMutation] = useMutation(UPLOAD_FILES_MUTATION, {
    onCompleted: ({ filesUpload }) => {
      const attachments = [];

      filesUpload.uploadResults.map(({ success, file }) => {
        if (success) {
          return attachments.push(file);
        }
        return setToastDetails({ detail: `Failed to upload ${file.name}. Please try again.`, severity: 'error' });
      });

      onAttachmentsAdded(attachments);
      return onImageUploadComplete();
    },
    onError: (errors) => {
      const { graphQLErrors, message } = errors;

      if (message) {
        setToastDetails({ detail: message, severity: 'error' });
      } else {
        graphQLErrors.map(({ message: gqlError }) => setToastDetails({ detail: gqlError, severity: 'error' }));
      }
    },
  });

  const [uploadInlineFilesMutation] = useMutation(UPLOAD_FILES_MUTATION, {
    onCompleted: ({ filesUpload }) => {
      setUploadingImage(false);

      let successCount = 0;

      filesUpload.uploadResults.map(({ success, file }) => {
        if (success) {
          successCount += 1;
          quill.enable(true);
          return insertImageToEditor({ url: `/uploaded-files/${file.uuid}` });
        }

        return setToastDetails({ detail: `Failed to upload ${file.name}. Please try again.`, severity: 'error' });
      });

      quill.setSelection(range.index + successCount, 0);

      return onImageUploadComplete();
    },
    onError: (errors) => {
      const { graphQLErrors, message } = errors;

      if (message) {
        setToastDetails({ detail: message, severity: 'error' });
      } else {
        graphQLErrors.map(({ message: gqlError }) => setToastDetails({ detail: gqlError, severity: 'error' }));
      }
    },
  });

  const getRange = (currentQuill) => {
    const currentRange = currentQuill.getSelection();

    if (currentRange) { return currentRange; }

    currentQuill.focus();
    return getSelection();
  };

  const saveToServerAndInsertInEditor = (files) => {
    setToastDetails(null);
    setUploadingImage(true);

    const currentQuill = editorRef.current.getQuill();
    setRange(getRange(currentQuill));

    currentQuill.enable(false);

    onImageUploadLoading();

    return uploadInlineFilesMutation({
      variables: { input: { files } },
    });
  };

  const handlePasteOrDropImage = (imageDataUrl, type, imageData) => {
    saveToServerAndInsertInEditor([imageData.toFile()]);
  };

  const openImagePicker = ({ onlyImages = false }) => {
    const input = document.createElement('input');

    if (onlyImages) { input.setAttribute('accept', 'image/*'); }
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', true);

    input.click();

    return input;
  };

  const imageHandler = () => {
    const input = openImagePicker({ onlyImages: true });

    input.onchange = () => {
      saveToServerAndInsertInEditor(input.files);
    };
  };

  const attachmentHandler = () => {
    const input = openImagePicker({});

    input.onchange = () => {
      onImageUploadLoading();

      return uploadAttachmentsMutation({
        variables: { input: { files: input.files } },
      });
    };
  };

  useEffect(() => {
    if (quill) {
      quill.getModule('toolbar').addHandler('image', imageHandler);
    }
  }, [quill]);

  const inputClasses = classNames(
    'block',
    'w-full',
    {
      'p-invalid': error,
    },
    editorClassName,
  );

  const toolbar = () => (
    <span className="ql-formats" onMouseDown={(e) => e.preventDefault()}>
      {
        formats.map((format) => {
          if (format === 'list') {
            return (
              <span key="list-format-buttons">
                <Button key="bullet-list-format-button" className="ql-list toolbar-button bg-none" value="bullet" aria-label={titleize(format)} />
                <Button key="ordered-list-format-button" className="ql-list toolbar-button bg-none" value="ordered" aria-label={titleize(format)} />
              </span>
            );
          } if (format === 'attachment') {
            return (
              <Button
                key="attachment-format-button"
                className="toolbar-button bg-none"
                type="button"
                size="small"
                icon="pi pi-paperclip"
                text
                aria-label={titleize(format)}
                onClick={attachmentHandler}
              />
            );
          } if (!IGNORE_IN_TOOLBAR_FORMATS.find((f) => f === format)) {
            return <Button key={`${format}-format-button`} className={`toolbar-button bg-none ql-${format}`} aria-label={titleize(format)} />;
          }
          return null;
        })
      }
    </span>
  );

  const onTextChange = ({ htmlValue }) => {
    onFieldChange(htmlValue);
    onChange();
  };

  const renderItem = (item) => {
    const div = document.createElement('div');

    if (item.disabled) {
      const header = document.createElement('div');
      header.innerHTML = `<strong>${item.label}</strong>`;
      div.appendChild(header);
    } else {
      const member = document.createElement('div');
      member.className = 'flex items-center';

      if (item.avatarUrl) {
        member.innerHTML = `
      <div class="bg-white border rounded-full p-0 mr-1 p-avatar p-component p-avatar-circle" data-pc-name="avatar" data-pc-section="root">
        <img alt="avatar" src=${item.avatarUrl} data-pc-section="image">
        </div>
        `;
      } else {
        member.innerHTML = `
      <div class="bg-white border rounded-full p-0 mr-1 p-avatar p-component p-avatar-circle" data-pc-name="avatar" data-pc-section="root">
        <span class="p-avatar-text" data-pc-section="label">${item.initials}</span>
      </div>
        `;
      }

      member.innerHTML += `${item.label}`;

      div.appendChild(member);
    }

    return div;
  };

  const mentionModule = () => {
    if (mentionsDisabled) { return {}; }

    return ({
      mention: {
        isolateCharacter: true,
        renderItem: (item) => renderItem(item),
        showDenotationChar: false,
        source: (searchTerm, renderList) => {
          let matches = mentionables;

          if (searchTerm.length) {
            matches = mentionables.filter((mentionable) => (
              mentionable.value.toLowerCase().includes(searchTerm.toLowerCase())));
          }

          const matchesWithGroupHeaders = [];
          let currentGroup;

          matches.map((match) => {
            if (currentGroup !== match.group) {
              matchesWithGroupHeaders.push({
                id: match.group,
                label: match.group,
                disabled: true,
              });
              currentGroup = match.group;
            }

            return matchesWithGroupHeaders.push(match);
          });

          matches = matchesWithGroupHeaders;

          renderList(matches, searchTerm);
        },
        dataAttributes: ['mention'],
      },
    });
  };

  const handleBlur = (event) => {
    setTimeout(() => {
      if (
        event.relatedTarget instanceof HTMLElement
        && event.relatedTarget.classList.contains('ql-clipboard')
      ) {
        return;
      }

      onBlur();
    }, 100);
  };

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      { label && <label htmlFor={name} className={`block mb-2 ${labelClassName}`}>{label}</label> }
      { uploadingImage && <ProgressBar mode="indeterminate" style={{ height: '6px' }} /> }
      { toastDetails && <Toast id={toastDetails.id} detail={toastDetails.detail} severity={toastDetails.severity} /> }
      <Editor
        id={name}
        ref={editorRef}
        onLoad={setQuill}
        onBlur={handleBlur}
        onFocus={onFocus}
        headerTemplate={toolbar()}
        name={name}
        placeholder={mentionsDisabled ? disabledMessage : placeholder}
        formats={mentionsDisabled ? without(formats, 'mention') : formats}
        modules={{
          ...mentionModule(),
          imageDropAndPaste: {
            handler: handlePasteOrDropImage,
          },
          clipboard: {
            matchVisual: false,
          },
        }}
        value={value}
        onTextChange={onTextChange}
        className={inputClasses}
        {...inputProps}
      />
      { renderFormFieldError(error) }
    </div>
  );
}

export default RichTextAreaMentionableInput;
