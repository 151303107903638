import React from 'react';
import { classNames } from 'primereact/utils';
import { Card } from 'primereact/card';

import Timeline from 'containers/Events/Show/TimelineTab/Timeline';

function CompleteTimeBlockForm({
  task,
  subtaskId,
  onCreate = () => {},
  header,
  timeBlock,
  refetch,
}) {
  const cardClassNames = classNames(
    'text-sm',
    'relative',
    'flex',
    'justify-center',
    'subtask-card',
  );

  return (
    <div>
      <Card
        title={header}
        key={`${subtaskId}-subtask-card`}
        className={cardClassNames}
      >
        <Timeline
          isEventTemplate={task.isEventTemplate}
          refetch={refetch}
          onCreate={onCreate}
          task={task}
          timeline={task?.event?.timeline}
          timeBlocks={[timeBlock]}
        />
      </Card>
    </div>
  );
}

export default CompleteTimeBlockForm;
