import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { SplitButton } from 'primereact/splitbutton';
import { Button } from 'primereact/button';

import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import EditableText from 'components/Form/EditableFields/Text';
import CompleteTaskForm from '.';
import SelectTimeBlockModal from './SelectTimeBlockForm/Modal';
import { TASK_UPDATE_MUTATION, SUBTASK_TEMPLATE_APPLY_MUTATION } from './graphql';

function CompleteTaskModal({
  show, task, currentVendorName, eventVendors, labels, onHide, actorOptions, refetch, toastRef, currentActor, subtaskTemplates,
}) {
  const [subtasks, setSubtasks] = useState(task.subtasks);
  const [showSelectTimeBlockModal, setShowSelectTimeBlockModal] = useState(false);

  useEffect(() => {
    setSubtasks(task.subtasks);
  }, [task.subtasks]);

  const {
    id,
    title,
    isTemplate,
    isEventTemplate,
    templateName,
    description,
    assignedToActor,
    dueDate,
    isUpdatableByCurrentActor,
    event,
  } = task;

  const {
    control, setValue, getValues, watch,
  } = useForm({
    defaultValues: {
      title,
      assignedToActorId: assignedToActor?.id,
      dueDate: dueDate ? new Date(dueDate) : null,
      description,
      templateName,
    },
  });

  const [isEditingTitle, setIsEditingTitle] = useState(!title);

  const [updateTaskMutation] = useMutation(TASK_UPDATE_MUTATION, {
    onCompleted: () => { refetch(); },
    onError: () => showErrorToast(toastRef, 'Error applying subtask template'),
  });

  const [applySubtaskTemplateMuation] = useMutation(SUBTASK_TEMPLATE_APPLY_MUTATION, {
    onCompleted: () => {
      showSuccessToast(toastRef, 'Subtask template applied!');
      refetch();
    },
    onError: () => showErrorToast(toastRef, 'Error applying subtask template'),
  });

  const updateTask = (values) => updateTaskMutation({ variables: { input: { id, ...values } } });

  const appendSubtask = ({ subtaskType, object }) => {
    setSubtasks(subtasks.concat([{
      subtaskType,
      ...object,
    }]));
  };

  const appendQuestion = () => appendSubtask({ subtaskType: 'QUESTION', object: { question: {} } });
  const appendFileRequest = () => appendSubtask({ subtaskType: 'FILE_REQUEST', object: { fileRequest: {} } });
  const appendChecklist = () => appendSubtask({ subtaskType: 'CHECKLIST', object: { checklist: {} } });
  const appendTimeBlockSubtask = () => appendSubtask({
    subtaskType: 'TIME_BLOCK',
    object: { timeBlock: { vendors: [], hostActors: [], subTimeBlocks: [] } },
  });

  const appendTimeBlock = () => {
    if (isTemplate || !event?.timeline?.timeBlocks?.length) {
      return appendTimeBlockSubtask();
    }
    return setShowSelectTimeBlockModal(true);
  };

  const isCreatingSubtask = () => subtasks.find((subtask) => !subtask.id);

  const removeEmptySubtasks = () => {
    setSubtasks(subtasks.filter((subtask) => subtask.id));
  };

  const header = () => (
    <EditableText
      text={getValues('title')}
      className="xxs:w-full md:w-6/12"
      defaultValue="New task"
      updatable={isUpdatableByCurrentActor}
      onUpdate={updateTask}
      isEditing={isEditingTitle}
      setIsEditing={setIsEditingTitle}
      control={control}
      setValue={setValue}
      getValues={getValues}
      name="title"
    />
  );

  const filterSubtaskTemplatesByType = (subtaskType) => subtaskTemplates.filter((subtaskTemplate) => subtaskTemplate.subtaskType === subtaskType);
  const mapSubtaskTemplates = (subtaskType) => {
    const filteredSubtaskTemplates = filterSubtaskTemplatesByType(subtaskType);

    if (!filteredSubtaskTemplates.length) { return [{ label: 'No templates', command: () => {} }]; }

    return (
      filteredSubtaskTemplates.map((subtaskTemplate) => ({
        label: subtaskTemplate.templateName,
        command: () => applySubtaskTemplateMuation({ variables: { input: { taskId: task.id, subtaskTemplateId: subtaskTemplate.id } } }),
      }))
    );
  };

  const buildSplitButton = ({
    subtaskType, label, icon, onAppend, disabled, disabledMessage,
  }) => (
    <>
      <Button
        tooltip={disabledMessage}
        tooltipOptions={{ position: 'top', showOnDisabled: true }}
        disabled={disabled}
        className={`xxs:hidden md:flex w-auto mb-0 text-white bg-bluegray-500 border-bluegray-500 ${!disabled && 'hidden'}`}
        type="button"
        icon={`pi pi-${icon}`}
        size="small"
      />
      <Button
        label={label}
        tooltip={disabledMessage}
        tooltipOptions={{ position: 'top' }}
        className={`w-full mb-2 md:hidden text-white bg-bluegray-500 border-bluegray-500 ${!disabled && 'hidden'}`}
        disabled={disabled}
        type="button"
        icon={`pi pi-${icon}`}
        size="small"
      />
      <SplitButton
        tooltip={label}
        tooltipOptions={{ position: 'top', showOnDisabled: true }}
        menuClassName="text-nowrap max-w-min bg-bluegray-500 border-bluegray-500 text-white max-h-80 task-action-button overflow-scroll"
        menuButtonClassName="bg-bluegray-500 border-bluegray-500 text-white"
        className={`xxs:hidden md:flex bw-auto mb-0 text-white ${disabled && 'hidden'}`}
        buttonClassName="-mr-3 pr-6 bg-bluegray-500 border-bluegray-500 text-white"
        type="button"
        onClick={onAppend}
        model={mapSubtaskTemplates(subtaskType)}
        icon={`pi pi-${icon}`}
        size="small"
      />
      <SplitButton
        label={label}
        tooltipOptions={{ position: 'top' }}
        menuClassName="text-nowrap max-w-min max-h-80 text-white task-action-button overflow-scroll"
        menuButtonClassName="bg-bluegray-500 border-bluegray-500 text-white"
        className={`w-full mb-2 md:hidden text-white ${disabled && 'hidden'}`}
        buttonClassName="-mr-3 pr-6 bg-bluegray-500 border-bluegray-500 text-white"
        type="button"
        onClick={onAppend}
        model={mapSubtaskTemplates(subtaskType)}
        icon={`pi pi-${icon}`}
        size="small"
      />
    </>
  );

  const buildTimeBlockDisabledMesssge = () => {
    if (!!assignedToActor && (isTemplate || event)) { return null; }

    if (isTemplate) {
      return 'Assignee required to request timeline details';
    }
    return 'Event and assignee required to request timeline details';
  };

  const timeBlockDisabledMessage = buildTimeBlockDisabledMesssge();

  const footer = () => (
    <div className="flex justify-between items-center">
      {
        !isCreatingSubtask() && (
          <div className="xxs:w-full md:w-auto md:flex">
            { buildSplitButton({
              subtaskType: 'QUESTION', label: 'Ask Question', icon: 'question', onAppend: appendQuestion,
            }) }
            { buildSplitButton({
              subtaskType: 'FILE_REQUEST', label: 'Request File', icon: 'file', onAppend: appendFileRequest,
            }) }
            { buildSplitButton({
              subtaskType: 'CHECKLIST', label: 'Add Checklist', icon: 'list-check', onAppend: appendChecklist,
            }) }
            {
              buildSplitButton({
                subtaskType: 'TIME_BLOCK',
                label: 'Request Timeline Details',
                icon: 'clock',
                onAppend: appendTimeBlock,
                disabled: !!timeBlockDisabledMessage,
                disabledMessage: timeBlockDisabledMessage,
              })
            }
          </div>
        )
      }
      { isTemplate && <p className="text-sm text-bluegray-500">This is a template</p> }
      { isEventTemplate && <p className="text-sm text-bluegray-500">This is part of an event template</p> }
    </div>
  );

  const onSelectTimeBlock = () => {
    refetch();
    setShowSelectTimeBlockModal(false);
  };

  return (
    <>
      <Dialog
        id="complete-task-form-modal"
        header={header()}
        footer={isUpdatableByCurrentActor && footer()}
        className="xl:w-3/4"
        visible={show}
        draggable={false}
        onHide={onHide}
        maximizable
        dismissableMask
        style={{ minHeight: '700px' }}
      >
        <CompleteTaskForm
          task={task}
          attachments={task.attachments}
          subtasks={subtasks}
          removeEmptySubtasks={removeEmptySubtasks}
          labels={labels}
          actorOptions={actorOptions}
          currentActor={currentActor}
          currentVendorName={currentVendorName}
          eventVendors={eventVendors}
          refetch={refetch}
          control={control}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          hideModal={onHide}
          hideSelectTimeBlockModal={() => setShowSelectTimeBlockModal(false)}
          toastRef={toastRef}
          updateTaskMutation={updateTask}
        />
      </Dialog>
      <SelectTimeBlockModal
        toastRef={toastRef}
        show={showSelectTimeBlockModal}
        onHide={onSelectTimeBlock}
        timeBlocks={event?.timeline?.timeBlocks}
        vendorName={assignedToActor?.vendor?.name}
        appendTimeBlock={appendTimeBlockSubtask}
        taskId={task.id}
      />
      <Toast ref={toastRef} position="bottom-left" className="z-[10000]" />
    </>
  );
}

export default CompleteTaskModal;
