import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import merge from 'lodash/merge';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';

import TextInput from 'components/Form/TextInput';
import Dropdown from 'components/Form/Dropdown';
import { showErrorToast } from 'utils/toastUtils';
import { TASK_CREATE_MUTATION } from './graphql';

function CreateTaskForm({
  isEventTemplate,
  isTemplate,
  templates,
  templateOptions,
  refetch,
  toastRef,
  hideModal,
}) {
  const navigate = useNavigate();
  const { eventId } = useParams();

  const {
    control, handleSubmit, setValue, watch, getValues,
  } = useForm();

  useEffect(() => {
    const templateId = getValues('templateId');

    if (templateId) {
      setValue('title', templates.find((template) => template.id === templateId).title);
    }
  }, [watch('templateId')]);

  const [createTaskMutation] = useMutation(TASK_CREATE_MUTATION, {
    onCompleted: ({ taskCreate }) => {
      refetch();

      if (eventId) {
        const templatesPath = isEventTemplate ? 'templates/' : '';
        navigate(`/app/events/${templatesPath}${eventId}/tasks/${taskCreate.task.id}`);
      } else {
        const objectPath = isTemplate ? '/app/tasks/templates' : '/app/tasks';

        navigate(`${objectPath}/${taskCreate.task.id}`);
      }

      hideModal();
    },
    onError: (error) => {
      const { graphQLErrors, message } = error;

      if (message) {
        showErrorToast(toastRef, error.message);
      } else {
        graphQLErrors.map(({ message: gqlError }) => (
          showErrorToast(toastRef, `Error creating task: ${gqlError}`)
        ));
      }
    },
  });

  const onSubmit = async ({
    title,
    templateId,
    templateName,
  }) => createTaskMutation({
    variables: {
      input: merge({
        title,
        eventId,
        templateId,
        isTemplate,
        isEventTemplate,
        templateName,
      }),
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="create-task-form">
      {
        isTemplate ? (
          <TextInput
            className="mb-4"
            focus
            control={control}
            name="templateName"
            label="Template Name"
            labelClassName="font-bold"
          />
        ) : (
          <Dropdown
            className="mb-4"
            control={control}
            required={false}
            name="templateId"
            label="Template"
            labelClassName="font-bold"
            tooltip={{ icon: 'pi pi-info-circle', message: 'Task will be created to match template' }}
            options={templateOptions}
            inputProps={{ showClear: true, placeholder: 'Apply a template' }}
          />
        )
      }
      <TextInput
        className="mb-4"
        focus={!isTemplate}
        control={control}
        required={!isTemplate}
        name="title"
        label="Title"
        labelClassName="font-bold"
      />
      <div className="flex justify-end w-full mt-2 sm:mt-0">
        <Button
          label="Create"
          type="submit"
          size="small"
        />
      </div>
    </form>
  );
}

export default CreateTaskForm;
