import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLazyQuery } from '@apollo/react-hooks';
import Table from 'components/Table';
import sortBy from 'lodash/sortBy';
import { Button } from 'primereact/button';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import { TabMenu } from 'primereact/tabmenu';
import { Column } from 'primereact/column';
import AvatarWithName from 'components/AvatarWithName';
import { titleize } from 'utils/stringUtils';
import CreateTaskModal from '../CreateTaskForm/Modal';
import CreateOrUpdateSubtaskTemplateModal from '../CreateOrUpdateSubtaskTemplateForm/Modal';
import { SUBTASK_TEMPLATE_FETCH_QUERY } from './graphql';

const SUBTASK_TYPES_TO_ICONS = {
  QUESTION: 'question',
  CHECKLIST: 'list-check',
  FILE_REQUEST: 'file',
  TIME_BLOCK: 'clock',
};
function ManageTemplatesForm({
  taskTemplates, subtaskTemplates, refetchTemplates,
}) {
  const navigate = useNavigate();
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const [showCreateOrUpdateSubtaskTemplateModal, setShowCreateOrUpdateSubtaskTemplateModal] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [subtaskTemplate, setSubtaskTemplate] = useState(null);
  const [subtaskTemplateModalAction, setSubtaskTemplateModalAction] = useState('create');
  const activeTab = activeTabIndex === 0 ? 'task' : 'subtask';

  useEffect(() => {
    if (!subtaskTemplate) { return; }

    if (!subtaskTemplate.id) {
      setSubtaskTemplate(subtaskTemplates[0]);
    } else {
      setSubtaskTemplate(subtaskTemplates.find((st) => st.id === subtaskTemplate.id));
    }
  }, [subtaskTemplates]);

  const actorColumn = (actor) => (
    <AvatarWithName
      id={actor.id}
      initials={actor.initials}
      avatarUrl={actor.avatarUrl}
      text={actor.name}
    />
  );
  const openTemplateModal = ({ id }) => {
    navigate(`/app/tasks/templates/${id}`);
  };

  const onCloseCreateOrUpdateSubtaskTemplateModal = () => {
    setSubtaskTemplate(null);
    setShowCreateOrUpdateSubtaskTemplateModal(false);
  };

  const [
    fetchSubtaskTemplate,
  ] = useLazyQuery(
    SUBTASK_TEMPLATE_FETCH_QUERY,
    {
      onCompleted: (data) => {
        setSubtaskTemplate(data.currentActor.subtaskTemplates[0]);
        setSubtaskTemplateModalAction('update');
        setShowCreateOrUpdateSubtaskTemplateModal(true);
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  const openCreateSubtaskModal = () => {
    setShowCreateOrUpdateSubtaskTemplateModal(true);
    setSubtaskTemplateModalAction('create');
  };

  const items = [
    { label: 'Task Templates' },
    { label: 'Subtask Templates' },
  ];
  const templates = () => (activeTab === 'task' ? taskTemplates : sortBy(subtaskTemplates, 'subtaskType'));
  const addSubtaskButtonItems = [
    {
      label: 'Checklist',
      icon: 'pi pi-list-check',
      command: () => {
        setSubtaskTemplate({ subtaskType: 'CHECKLIST', checklist: {} });
        openCreateSubtaskModal();
      },
    },
    {
      label: 'Question',
      icon: 'pi pi-question',
      command: () => {
        setSubtaskTemplate({ subtaskType: 'QUESTION', question: {} });
        openCreateSubtaskModal();
      },
    },
    {
      label: 'File Request',
      icon: 'pi pi-file',
      command: () => {
        setSubtaskTemplate({ subtaskType: 'FILE_REQUEST', fileRequest: {} });
        openCreateSubtaskModal();
      },
    },
  ];
  const subtaskTypeGroupHeader = ({ subtaskType }) => <p className="text-lg font-semibold">{`${titleize(subtaskType)}s`}</p>;
  const templateNameColumn = ({ templateName, subtaskType }) => {
    if (activeTab === 'task') { return <p>{ templateName }</p>; }

    return (
      <div className="flex items-center">
        <i className={`pi pi-${SUBTASK_TYPES_TO_ICONS[subtaskType]} mr-2`} />
        <p>{ templateName }</p>
      </div>
    );
  };
  const onTemplateRowClick = ({ data }) => (
    activeTab === 'task' ? openTemplateModal({ id: data.id }) : fetchSubtaskTemplate({ variables: { filters: { id: data.id } } })
  );

  return (
    <>
      <div className="flex justify-between mb-4">
        <div />
        <TabMenu model={items} onTabChange={({ index }) => setActiveTabIndex(index)} activeIndex={activeTabIndex} />
        {
          activeTab === 'task' ? (
            <Button
              rounded
              className="h-8 w-8"
              icon="pi pi-plus"
              onClick={() => setShowCreateTaskModal(true)}
            />
          ) : (
            <div>
              <Tooltip target=".add-subtask-template-button .p-speeddial-action" position="left" />
              <SpeedDial
                className="add-subtask-template-button right-5 z-[10000]"
                buttonClassName="h-8 w-8"
                model={addSubtaskButtonItems}
                direction="down"
              />
            </div>
          )
        }
      </div>
      <Table
        data={templates()}
        pageSize={10}
        clickable
        onRowClick={onTemplateRowClick}
        defaultSortField={activeTab === 'task' ? null : 'subtaskType'}
        defaultSortOrder={-1}
        groupRowsBy={activeTab === 'task' ? null : 'subtaskType'}
        rowGroupHeaderTemplate={subtaskTypeGroupHeader}
      >
        <Column
          field="templateName"
          header="Name"
          body={templateNameColumn}
        />
        <Column
          field="createdByActor"
          header="Created By"
          className="xxs:hidden lg:table-cell"
          headerClassName="xxs:hidden lg:table-cell"
          body={({ createdByActor }) => actorColumn(createdByActor)}
        />
        <Column
          field="lastUpdatedByActor"
          header="Last Updated By"
          className="xxs:hidden lg:table-cell"
          headerClassName="xxs:hidden lg:table-cell"
          body={({ lastUpdatedByActor }) => actorColumn(lastUpdatedByActor)}
        />
      </Table>
      <CreateTaskModal
        isTemplate
        refetch={refetchTemplates}
        show={showCreateTaskModal}
        onHide={() => setShowCreateTaskModal(false)}
      />
      {
        subtaskTemplate && (
          <CreateOrUpdateSubtaskTemplateModal
            subtaskTemplate={subtaskTemplate}
            action={subtaskTemplateModalAction}
            refetch={refetchTemplates}
            show={showCreateOrUpdateSubtaskTemplateModal}
            onHide={onCloseCreateOrUpdateSubtaskTemplateModal}
          />
        )
      }
    </>
  );
}

export default ManageTemplatesForm;
