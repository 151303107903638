import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import cloneDeep from 'lodash/cloneDeep';
import identity from 'lodash/identity';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import { IconField } from 'primereact/iconfield';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { OverlayPanel } from 'primereact/overlaypanel';

import { titleize } from 'utils/stringUtils';
import { showErrorToast } from 'utils/toastUtils';
import Filters from 'components/Filters';
import { VENDOR_CATEGORIES_QUERY } from 'graphql/shared';
import { CATEGORY_BACKGROUND_COLOR_MAPPING } from 'constants/colors';
import ManageDisplaySettings from './ManageDisplaySettings';
import VendorCard from './VendorCard';
import { VENDORS_QUERY } from './graphql';

const DEFAULT_DISPLAY_SETTINGS = {
  sortOption: 'category',
};
const SORT_OPTIONS = [
  {
    value: 'category',
    label: 'Category',
  },
  {
    value: 'name',
    label: 'Name',
  },
  {
    value: 'events',
    label: 'Number of Events',
  },
];
function Vendors({ currentVendorId }) {
  const [displaySettings, setDisplaySettings] = useState(JSON.parse(localStorage.getItem('vendorDisplaySettings')) || DEFAULT_DISPLAY_SETTINGS);
  const [selectedKeys, setSelectedKeys] = useState(JSON.parse(localStorage.getItem('selectedVendorFilters')) || []);
  const [groupedEventVendorsByVendor, setGroupedEventVendorsByVendor] = useState({});
  const [sortedVendors, setSortedVendors] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState(false);
  const [refetchingEventVendor, setRefetchingEventVendor] = useState(false);
  const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('vendorFilters')) || {});
  const [currentVendor, setCurrentVendor] = useState(null);
  const [eventVendors, setEventVendors] = useState([]);
  const [vendorCategories, setVendorCategories] = useState([]);
  const toastRef = useRef(null);
  const displaySettingsRef = useRef(null);
  const filtersButtonRef = useRef(null);

  const setSelectedSortOption = (sortOption) => {
    setDisplaySettings({ ...displaySettings, sortOption });
  };

  const sortVendors = () => {
    switch (displaySettings.sortOption) {
      case 'category': {
        return sortBy(groupedEventVendorsByVendor, (ev) => ev[0].vendor.categories[0]);
      }
      case 'events': {
        return sortBy(groupedEventVendorsByVendor, (ev) => -ev.length);
      }
      case 'name': {
        return sortBy(groupedEventVendorsByVendor, (ev) => ev[0].vendor.name);
      }
      default: return null;
    }
  };

  useEffect(() => {
    window.localStorage.setItem('vendorDisplaySettings', JSON.stringify(displaySettings));
    setSortedVendors(sortVendors());
  }, [displaySettings]);

  /* eslint-disable no-param-reassign */
  const groupEventVendorsByVendor = () => eventVendors.reduce((groupedVendors, eventVendor) => {
    groupedVendors[eventVendor.vendor.id] ||= [];
    groupedVendors[eventVendor.vendor.id].push(eventVendor);
    return groupedVendors;
  }, {});
  /* eslint-enable no-param-reassign */

  useEffect(() => {
    setGroupedEventVendorsByVendor(groupEventVendorsByVendor());
  }, [eventVendors]);

  useEffect(() => {
    setSortedVendors(sortVendors());
  }, [groupedEventVendorsByVendor]);

  const buildQueryFilters = () => {
    let queryFilters = {};

    if (!isEmpty(filters)) {
      queryFilters = { vendor: filters };
    }

    if (searchTerm) {
      queryFilters.vendor ||= {};
      queryFilters.vendor.name = { includes: searchTerm };
    }

    return pickBy(queryFilters, identity);
  };

  const [
    fetchVendors,
  ] = useLazyQuery(
    VENDORS_QUERY,
    {
      onCompleted: (data) => {
        setCurrentVendor(data.currentVendor);

        const updatedEventVendors = map(data.currentActor.eventVendors.edges, 'node');

        if (eventVendors.length && refetchingEventVendor) {
          const updatedEventVendor = updatedEventVendors[0];

          setEventVendors(eventVendors.map((eventVendor) => (eventVendor.id === updatedEventVendor.id ? updatedEventVendor : eventVendor)));
        } else {
          setEventVendors(updatedEventVendors);
        }
      },
      onError: ({ graphQLErrors }) => {
        graphQLErrors.map(({ message }) => (
          showErrorToast(toastRef, `Error fetching vendors ${message}`)
        ));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  const handleFetchVendors = (input = { variables: { filters: buildQueryFilters() } }) => {
    setRefetchingEventVendor(!!filters?.id?.eq);

    const clonedInput = cloneDeep(input);
    const inputFilters = clonedInput.variables.filters;

    inputFilters.vendor = inputFilters.vendor || {};
    inputFilters.vendor.id = {
      ...(inputFilters.vendor.id || {}),
      not: [currentVendorId],
    };

    fetchVendors(clonedInput);
  };

  useEffect(() => {
    window.localStorage.setItem('vendorDisplaySettings', JSON.stringify(displaySettings));
    window.localStorage.setItem('vendorFilters', JSON.stringify(filters));
    handleFetchVendors();
  }, [searchTerm, filters]);

  useQuery(
    VENDOR_CATEGORIES_QUERY,
    {
      onCompleted: (data) => setVendorCategories(data.vendorCategories),
    },
  );

  const filterItemTemplate = (node) => {
    if (!node.key) { return null; }

    if (node.key.startsWith('category')) {
      return (
        <div className="flex items-center">
          <Tag className={CATEGORY_BACKGROUND_COLOR_MAPPING[node.label.toLowerCase()]} value={node.label} />
        </div>
      );
    }

    return (
      <div className="flex items-center">
        <p>{node.label}</p>
      </div>
    );
  };

  const filterOptions = () => {
    if (!vendorCategories.length) { return {}; }

    return ([
      {
        key: 'filter-type-category',
        label: 'Category',
        children: vendorCategories.map((category) => ({ key: `category-${category}`, label: titleize(category), value: category })),
      },
    ].filter((item) => item.key));
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <p className="font-bold text-xl">Vendors</p>
          <Button
            ref={filtersButtonRef}
            rounded
            text
            className="h-8 w-8 mx-1"
            icon="pi pi-filter"
            onClick={() => { setShowFilters(!showFilters); }}
          />
        </div>
        <div className="flex items-center">
          <IconField className="mr-2">
            <InputIcon className="pi pi-search" />
            <InputText placeholder="Search by name..." onInput={({ target }) => setSearchTerm(target.value)} />
          </IconField>
          <Button
            rounded
            className="h-8 w-8 mr-1 manage-display-settings-button"
            icon="pi pi-sliders-h"
            data-pr-tooltip="Manage Display Settings"
            data-pr-position="top"
            onClick={(e) => displaySettingsRef?.current?.toggle(e)}
          />
          <OverlayPanel ref={displaySettingsRef}>
            <ManageDisplaySettings
              sortOptions={SORT_OPTIONS}
              displaySettings={displaySettings}
              setSelectedSortOption={setSelectedSortOption}
            />
          </OverlayPanel>
          <Tooltip target=".manage-display-settings-button" className="mr-4" />
        </div>
      </div>
      <Filters
        className="left-[8rem]"
        filtersButtonRef={filtersButtonRef}
        onClearFilters={() => setSelectedKeys({})}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        filterOptions={filterOptions()}
        filterItemTemplate={filterItemTemplate}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        setFilters={setFilters}
        selectedKeysLocalStorageName="selectedVendorFilters"
        filtersLocalStorageName="vendorFilters"
      />
      <div className={`content ${sortedVendors.length > 3 ? 'grid' : 'flex'} grid-cols-[repeat(auto-fit,minmax(17rem,1fr))] gap-1 mt-10`}>
        {
          map(sortedVendors, (ev, vendorId) => (
            <div className="xs:w-full sm:w-72" key={`${vendorId}-vendor-card`}>
              <VendorCard
                vendor={ev[0].vendor}
                toastRef={toastRef}
                eventVendors={ev}
                currentVendor={currentVendor}
                refetchVendors={handleFetchVendors}
              />
            </div>
          ))
        }
      </div>
      <Toast ref={toastRef} position="bottom-left" />
    </>
  );
}

export default Vendors;
