import gql from 'graphql-tag';
import PAGE_INFO_FIELDS from 'graphql/fragments/pageInfo';

const TEMPLATES_QUERY = gql`
  query EventTemplates {
    currentVendor {
      id
      name
    }
    currentActor {
      id
      eventTemplates {
        id
        isTemplate
        templateName
        createdByActor {
          id
          name
          initials
          avatarUrl
        }
        lastUpdatedByActor {
          id
          name
          initials
          avatarUrl
        }
      }
    }
  }
`;

const EVENT_QUERY = gql`
  query Event($filters: EventFilterInput) {
    currentVendor {
      id
      name
    }
    currentActor {
      id
      events(filters: $filters) {
        edges {
          cursor
          node {
            id
            name
            startDate
            eventVendors {
              id
              categories
              vendor {
                id
                name
                initials
              }
            }
            eventHostActors {
              id
              hostActor {
                id
              }
            }
            currentEventHostActor {
              id
              status
              currentActorIsLastInviteActioner
              inviteApprovalSubjectType
              requestedByActor {
                id
                name
                initials
                avatarUrl
                vendor {
                  id
                  name
                  initials
                  categories
                }
              }
              invitedByActor {
                id
                name
                initials
                avatarUrl
                vendor {
                  id
                  name
                  initials
                  categories
                }
              }
              inviteLastActionedByActor {
                id
                name
                initials
                avatarUrl
                vendor {
                  id
                  name
                  initials
                  categories
                }
              }
              inviteLastActionedAt
            }
            currentEventVendor {
              id
              status
              currentActorIsOnEvent
              currentActorIsLastInviteActioner
              currentVendorIsLastInviteActioner
              inviteApprovalSubjectType
              requestedByActor {
                id
                name
                initials
                avatarUrl
                vendor {
                  id
                  name
                  initials
                  categories
                }
              }
              invitedByActor {
                id
                name
                initials
                avatarUrl
                vendor {
                  id
                  name
                  initials
                  categories
                }
              }
              inviteLastActionedByActor {
                id
                name
                initials
                avatarUrl
                vendor {
                  id
                  name
                  initials
                  categories
                }
              }
              inviteLastActionedAt
            }
          }
        }
      }
    }
  }
`;

const EVENTS_QUERY = gql`
  ${PAGE_INFO_FIELDS}

  query Events($first: Int, $last: Int, $before: String, $after: String, $filters: EventFilterInput) {
    currentActor {
      id
      actorType
      events(first: $first, last: $last, before: $before, after: $after, filters: $filters) {
        totalRecords
        pageInfo {
          ...PageInfoFields
        }
        edges {
          cursor
          node {
            id
            name
            venues {
              id
              vendor {
                id
                name
                initials
                avatarUrl
              }
            }
            currentEventVendor {
              id
              status
            }
            currentEventHostActor {
              id
              status
            }
            startDate
            hostActors {
              id
              initials
              name
              avatarUrl
            }
          }
        }
      }
    }
  }
`;

const EVENT_CREATE_MUTATION = gql`
  mutation CreateEvent($input: EventCreateMutationInput!) {
    eventCreate(input: $input) {
      event {
        id
        name
        startDate
        endDate
      }
    }
  }
`;

export {
  EVENT_QUERY,
  EVENTS_QUERY,
  TEMPLATES_QUERY,
  EVENT_CREATE_MUTATION,
};
