import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import map from 'lodash/map';
import { classNames } from 'primereact/utils';

import { showErrorToast } from 'utils/toastUtils';
import EditableDropdown from 'components/Form/EditableFields/Dropdown';
import { EVENT_UPDATE_MUTATION } from './graphql';
import Timeline from './Timeline';

const TIME_ZONES_MAPPING = {
  AMERICA_CHICAGO: 'CST',
  AMERICA_NEW_YORK: 'EST',
  AMERICA_LOS_ANGELES: 'PST',
  AMERICA_DENVER: 'MST',
  PACIFIC_HONOLULU: 'Hawaii',
  AMERICA_ANCHORAGE: 'Alaska',
};
export default function TimelineTab({
  eventsLoading, refetchEvent, event, isEventTemplate,
}) {
  const toastRef = useRef();
  const [timeline, setTimeline] = useState(event.timeline);
  const [isEditingTimeZone, setIsEditingTimeZone] = useState(false);
  const { eventId } = useParams();

  const {
    control, setValue, watch,
  } = useForm({
    defaultValues: { timeZone: event.timeZone },
  });

  useEffect(() => {
    setTimeline(event.timeline);
    setValue('timeZone', event.timeZone);
  }, [event]);

  const [eventUpdateMutation] = useMutation(EVENT_UPDATE_MUTATION, {
    onCompleted: () => {
      refetchEvent();
      setIsEditingTimeZone(false);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ));
    },
  });

  const timeZoneOptions = map(TIME_ZONES_MAPPING, (label, value) => ({
    label,
    value,
  }));

  const hoverClassNames = classNames(
    'text-nowrap',
    'border',
    'hover:bg-white',
    'border-blue-500',
    '[&:not(:hover)]:bg-blue-500',
    '[&:not(:hover)]:text-white',
    'hover:text-blue-500',
  );

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <div className="flex items-center">
            <p className="font-bold text-xl">Timeline</p>
          </div>
          <div className="flex items-center">
            <p className="mr-2">Time Zone:</p>
            <EditableDropdown
              control={control}
              name="timeZone"
              updatable={event.isUpdatableByCurrentActor}
              value={watch('timeZone')}
              setValue={setValue}
              hoverClassNames={hoverClassNames}
              options={timeZoneOptions}
              isEditing={isEditingTimeZone}
              setIsEditing={setIsEditingTimeZone}
              onUpdate={(timeZone) => eventUpdateMutation({ variables: { input: { id: eventId, timeZone } } })}
            />
          </div>
        </div>
      </div>
      <Timeline
        isEventTemplate={isEventTemplate}
        loading={eventsLoading}
        refetch={refetchEvent}
        timeline={timeline}
        timeBlocks={timeline.timeBlocks}
        showCreate
      />
    </div>
  );
}
